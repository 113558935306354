<template>
    <div class="intervention">
        <div class="title">
            <svg v-if="intervention.collectTypeID == 1 || intervention.collectTypeID == 2 || intervention.collectTypeID == 3 || intervention.collectTypeID == 4" role="img" class="icon"><use :xlink:href="'/images/symbols.svg#ico-collectType' + intervention.collectTypeID"></use></svg>
            <svg v-else role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-collectType5"></use></svg>
            <h1 v-if="intervention.collectTypeID">{{ collectTypes.find(x => x.id === intervention.collectTypeID).name }}</h1>
            <h1 v-else>Non applicable</h1>
        </div>
        <TagBlock :metadata="breakageTypes" :tags="intervention.breakageTypes" />
        <div class="informationContainer" :class="[intervention.noteCreator == null && intervention.noteInspector == null && status != 'IN_PROGRESS' ? 'lastElement' : '']">
            <InformationBlockEditable type="selectCollect" :description="$t('request.details.informations.collect')" :status="status" :value="intervention.collectTypeID ? collectTypes.find(x => x.id === intervention.collectTypeID).name : ''" :intervention="intervention" :canEdit="isSameInspector" origin="intervention" />
            <InformationBlockEditable type="selectBreakage" :description="$t('request.details.informations.breakage')" :status="status" :value="intervention.breakageTypes[0] ? breakageTypes.find(x => x.id === intervention.breakageTypes[0]).name : ''" :intervention="intervention" :canEdit="isSameInspector" origin="intervention" />
            <InformationBlockEditable type="select" :description="$t('request.details.informations.bin.format')" :status="status" :value="intervention.binFormat ? binFormats.find(x => x.id === intervention.binFormat).name : ''" :intervention="intervention" :canEdit="isSameInspector" origin="intervention" />
            <InformationBlockEditable type="input" :description="$t('request.details.informations.bin.number')" :status="status" :value="intervention.binNumber" :intervention="intervention" :canEdit="isSameInspector" origin="intervention" />
            <InformationBlockEditable type="inputReplace" :description="$t('request.details.informations.bin.numberReplace')" :status="status" :value="intervention.binNumberReplace" :intervention="intervention" :canEdit="isSameInspector" origin="intervention" />
            <InformationBlockEditable type="inputQuantity" :description="$t('request.details.informations.bin.quantity')" :status="status" :value="intervention.binQty.toString()" :intervention="intervention" :canEdit="isSameInspector" origin="intervention" />
        </div>
        <CommentBlock :commentID="intervention.id" :noteCreator="intervention.noteCreator" :noteInspector="intervention.noteInspector" :status="status" :canEdit="isSameInspector" origin="intervention" />
    </div>
</template>

<script>
import CommentBlock from '@/components/details/commentBlock.vue'
import InformationBlockEditable from '@/components/details/informationBlockEditable.vue'
import TagBlock from '@/components/details/tagBlock.vue'

export default {
    name: 'Intervention',
    components: {
        CommentBlock,
        TagBlock,
        InformationBlockEditable
    },
    props: {
        status: String,
        intervention: Object
    },
    computed: {
        breakageTypes() {
            return this.$store.getters.allBreakageTypes
        },
        collectTypes() {
            return this.$store.getters.allCollectTypes
        },
        binFormats() {
            return this.$store.getters.allBinFormats
        },
        isSameInspector() {
            return this.$store.getters.isSameInspector(this.$route.params.requestID)
        }
    }
}
</script>
