import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Compressor from 'compressorjs'
import { EventBus } from '@/classes/eventBus.js'
import router from './router'
import filterManager from './classes/filterManager.js'

Vue.use(Vuex)

const API_URL = 'https://roussillon.dev' // PROD
// const API_URL = 'https://backendstaging.roussillon.dev' // DEV
// const API_URL = 'http://localhost:8080' // TEST

export default new Vuex.Store({
    state: {
        collectTypes: [],
        binFormats: [],
        addressTags: [],
        breakageTypes: [],
        cities: [],
        requests: [],
        requestsLength: []
    },
    getters: {
        allCollectTypes: state => state.collectTypes,
        allBinFormats: state => state.binFormats,
        allAddressTags: state => state.addressTags,
        allBreakageTypes: state => state.breakageTypes,
        allCities: state => state.cities,
        allRequests: state => state.requests,

        isResults: state => (state.requests.length > 0) ? true : false,

        selectedRequest: (state) => (id) => {
            return state.requests.find(x => x.reportID === id)
        },
        isSameInspector: (state) => (id) => (state.requests.find(x => x.reportID === id).inspectorName == localStorage.inspectorName) ? true : false,
        requestStatus: (state) => (id) => {
            return state.requests.find(x => x.reportID === id).status
        },

        allRequestsLength: state => state.requestsLength
    },
    mutations: {
        setRequestList: (state, requestList) => {
            state.requests = requestList

            var allFilteredRequests = 0
            var myRequest = 0
            var WAITING = 0
            var COMPLETED = 0
            var CANCELED = 0
            var ARCHIVES = 0
            for (var i = 0; i < state.requests.length; i++) {
                if(state.requests[i].flag == 1) {
                    if(state.requests[i].status != 'COMPLETED' && state.requests[i].status != 'CANCELED') { allFilteredRequests++ }
                    if(state.requests[i].inspectorName == localStorage.inspectorName && state.requests[i].status != 'COMPLETED' && state.requests[i].status != 'CANCELED') { myRequest++ }
                    if(state.requests[i].status == 'WAITING') { WAITING++ }
                    if(state.requests[i].status == 'COMPLETED') { COMPLETED++ }
                    if(state.requests[i].status == 'CANCELED') { CANCELED++ }
                    if(state.requests[i].status == 'COMPLETED' || state.requests[i].status == 'CANCELED') { ARCHIVES++ }
                }
            }
            state.requestsLength = [allFilteredRequests, myRequest, WAITING, COMPLETED, CANCELED, ARCHIVES]
        },
        setCollectTypes: (state, collectTypes) => {
            var collect = [{id: 0, name: 'Non applicable', defaultBinFormatId: null, isPublished: true}]
            collectTypes.data.metadata.collectTypes.forEach(x => {
                if (x.isPublished) {
                    collect.push(x)
                }
            })
            state.collectTypes = collect
        },
        setBinFormats: (state, binFormats) => {
            var binFormat = []
            binFormats.data.metadata.binFormats.forEach(x => {
                if (x.isPublished) {
                    binFormat.push(x)
                }
            })
            state.binFormats = binFormat
        },
        setAddressTags: (state, addressTags) => {
            state.addressTags = addressTags.data.metadata.addressTags
        },
        setBreakageTypes: (state, breakageTypes) => {
            var breakage = []
            breakageTypes.data.metadata.breakageTypes.forEach((x) => {
                if (x.isPublished && x.isAvailable) {
                    breakage.push(x)
                }
            })
            breakage.sort(function(a, b){
                if(a.name < b.name) { return -1 }
                if(a.name > b.name) { return 1 }
                return 0
            })
            state.breakageTypes = breakage
        },
        setCities: (state, cities) => {
            state.cities = cities.data.metadata.cities
        },
        setRequests: (state, requests) => {
            state.requests = requests.data.reports

            var allFilteredRequests = 0
            var myRequest = 0
            var WAITING = 0
            var COMPLETED = 0
            var CANCELED = 0
            var ARCHIVES = 0
            for (var i = 0; i < state.requests.length; i++) {
                if(state.requests[i].flag == 1) {
                    if(state.requests[i].status != 'COMPLETED' && state.requests[i].status != 'CANCELED') { allFilteredRequests++ }
                    if(state.requests[i].inspectorName == localStorage.inspectorName && state.requests[i].status != 'COMPLETED' && state.requests[i].status != 'CANCELED') { myRequest++ }
                    if(state.requests[i].status == 'WAITING') { WAITING++ }
                    if(state.requests[i].status == 'COMPLETED') { COMPLETED++ }
                    if(state.requests[i].status == 'CANCELED') { CANCELED++ }
                    if(state.requests[i].status == 'COMPLETED' || state.requests[i].status == 'CANCELED') { ARCHIVES++ }
                }
            }
            state.requestsLength = [allFilteredRequests, myRequest, WAITING, COMPLETED, CANCELED, ARCHIVES]
        },
        filterRequests: (state, value) => {
            // envoyer le filtre au filterManager
            filterManager.setFilter( value )
        },
        setRequestStatus: (state, value) => {
            console.log('Entered setRequestStatus') // eslint-disable-line no-console
            axios.patch(API_URL + '/api/v1/reports/' + state.requests.find(x => x.reportID == value.id).id + '/status/' + value.status, null, { headers: { token: localStorage.token }, data: { noteCancelation: value.noteCancelation } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                    EventBus.$emit('needRefresh', true)
                } else {
                    state.requests.find(x => x.reportID == value.id).status = value.status
                    state.requests.find(x => x.reportID == value.id).inspectorName = value.inspectorName

                    var allFilteredRequests = 0
                    var myRequest = 0
                    var WAITING = 0
                    var COMPLETED = 0
                    var CANCELED = 0
                    var ARCHIVES = 0
                    for (var i = 0; i < state.requests.length; i++) {
                        if(state.requests[i].flag == 1) {
                            if(state.requests[i].status != 'COMPLETED' && state.requests[i].status != 'CANCELED') { allFilteredRequests++ }
                            if(state.requests[i].inspectorName == localStorage.inspectorName && state.requests[i].status != 'COMPLETED' && state.requests[i].status != 'CANCELED') { myRequest++ }
                            if(state.requests[i].status == 'WAITING') { WAITING++ }
                            if(state.requests[i].status == 'COMPLETED') { COMPLETED++ }
                            if(state.requests[i].status == 'CANCELED') { CANCELED++ }
                            if(state.requests[i].status == 'COMPLETED' || state.requests[i].status == 'CANCELED') { ARCHIVES++ }
                        }
                    }
                    state.requestsLength = [allFilteredRequests, myRequest, WAITING, COMPLETED, CANCELED, ARCHIVES]
                }
            })

        },
        saveRequest: (state, value) => {
            console.log('Entered saveRequest') // eslint-disable-line no-console
            axios.patch(API_URL + '/api/v1/reports/' + state.requests.find(x => x.reportID == value.id).id, null, { headers: { token: localStorage.token }, data: { payload: JSON.stringify(value) } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                }
            })
            state.requests.find(x => x.reportID == value.id).noteInspector = value.noteInspector
        },
        saveIntervention: (state, value) => {
            console.log('Entered saveIntervention') // eslint-disable-line no-console
            axios.patch(API_URL + '/api/v1/interventions/' + value.interventionID, null, { headers: { token: localStorage.token }, data: { payload: JSON.stringify(value) } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                }
            })
            if(value.binFormat) {
                state.requests.find(x => x.reportID == value.id).interventions.find(x => x.id == value.interventionID).binFormat = value.binFormat
            }
            if(value.binNumber) {
                state.requests.find(x => x.reportID == value.id).interventions.find(x => x.id == value.interventionID).binNumber = value.binNumber
            }
            if(value.noteInspector) {
                state.requests.find(x => x.reportID == value.id).interventions.find(x => x.id == value.interventionID).noteInspector = value.noteInspector
            }
            if(value.collectId) {
                state.requests.find(x => x.reportID == value.id).interventions.find(x => x.id == value.interventionID).collectTypeID = value.collectId
            }
            if(value.breakage) {
                state.requests.find(x => x.reportID == value.id).interventions.find(x => x.id == value.interventionID).breakageTypes = value.breakage
            }
            if(value.binQty) {
                state.requests.find(x => x.reportID == value.id).interventions.find(x => x.id == value.interventionID).binQty = value.binQty
            }
        },
        setNewImage: (state, value) => {
            console.log("Entered setNewImage") // eslint-disable-line no-console
            new Compressor(value.file, {
                quality: 0.6,
                success(result) {
                    var reader = new FileReader()
                    reader.readAsDataURL(result)
                    reader.onload = function () {
                        axios.post(API_URL + '/api/v1/reports/' + state.requests.find(x => x.reportID == value.reportID).id + '/image', null, { headers: { token: localStorage.token }, data: { image: reader.result } }).then(response => {
                            if (response.data.status == 'error') {
                                EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                            } else {
                                state.requests.find(x => x.reportID == value.reportID).picturesInspector.push({ id: response.data.data.image.id, path: response.data.data.image.path, thumbPath: response.data.data.image.thumbPath})
                            }
                        })
                    }
                }
            })
        },
        setNewImageCreate: (state, value) => {
            console.log("Entered setNewImageCreate") // eslint-disable-line no-console
            new Compressor(value.image, {
                quality: 0.6,
                success(result) {
                    var reader = new FileReader()
                    reader.readAsDataURL(result)
                    reader.onload = function () {
                        axios.post(API_URL + '/api/v1/report/image', null, { headers: { token: localStorage.token }, data: { session_key: value.sessionID, filename: value.filename, image: reader.result } }).then(response => {
                            if (response.data.status == 'error') {
                                EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                            } else {
                                EventBus.$emit('addNewImageCreate', {'fileID': response.data.fileID, 'fileHash': response.data.fileHash, 'imageTempPath': value.imageTempPath })
                            }
                        })
                    }
                }
            })
        },
        removePicture: (state, value) => {
            console.log("Entered removePicture") // eslint-disable-line no-console
            axios.delete(API_URL + '/api/v1/reports/' + state.requests.find(x => x.reportID == value.reportID).id + '/image/' + value.pictureID, { headers: { token: localStorage.token } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                } else {
                    state.requests.find(x => x.reportID == value.reportID).picturesInspector.splice(state.requests.find(x => x.reportID == value.reportID).picturesInspector.findIndex(x => x.id == value.pictureID), 1)
                }
            })
        },
        removePictureCreate: (state, value) => {
            console.log("Entered removePictureCreate") // eslint-disable-line no-console
            axios.delete(API_URL + '/api/v1/report/image', { headers: { token: localStorage.token }, data: { fileID: value.fileID, fileHash: value.fileHash } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                }
            })
        },
        auth: (state, value) => {
            console.log("Entered auth") // eslint-disable-line no-console
            var loginInfos = value.username + ':' + value.password
            var encoded = btoa(loginInfos)
            axios.get(API_URL + '/api/v1/auth', { headers: { Authorization: 'Basic ' + encoded } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                    EventBus.$emit('authStatus', false)
                } else {
                    localStorage.token = response.data.data.token
                    localStorage.inspectorName = response.data.data.inspectorName
                    EventBus.$emit('authStatus', true)
                }
            })
        },
        logout() {
            console.log("Entered logout") // eslint-disable-line no-console
            axios.get(API_URL + '/api/v1/logout', { headers: { token: localStorage.token } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                } else {
                    localStorage.clear()
                }
            })
        },
        createNewRequest(state, value) {
            console.log("Entered createNewRequest") // eslint-disable-line no-console
            axios.post(API_URL + '/api/v1/reports/', null, { headers: { token: localStorage.token }, data: { payload: JSON.stringify(value) } }).then(response => {
              if (response.data.status == 'error') {
                EventBus.$emit('loading', false)
                EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
              } else {
                EventBus.$emit('loading', false)
                EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Succès', 'description': "La requête pour la ville de " + value.city + " a bien été envoyée.", 'actions': ['Ok'], 'reloadPage': true })
              }
            })
        }
    },
    actions: {
        setRequestList (context, value) {
            context.commit('setRequestList', value)
        },
        filterRequests (context, value) {
            context.commit('filterRequests', value)
        },
        setRequestStatus (context, value) {
            context.commit('setRequestStatus', value)
        },
        saveRequest (context, value) {
            context.commit('saveRequest', value)
        },
        saveIntervention (context, value) {
            context.commit('saveIntervention', value)
        },
        setNewImage (context, value) {
            context.commit('setNewImage', value)
        },
        setNewImageCreate (context, value) {
            context.commit('setNewImageCreate', value)
        },
        removePicture (context, value) {
            context.commit("removePicture", value)
        },
        removePictureCreate (context, value) {
            context.commit("removePictureCreate", value)
        },
        auth (context, value) {
            context.commit("auth", value)
        },
        logout (context) {
            context.commit("logout")
        },
        createNewRequest (context, value) {
            context.commit("createNewRequest", value)
        },
        getRequests({ commit }) {
            axios.get(API_URL + '/api/v1/reports/', { headers: { token: localStorage.token } }).then(response => {
                if (response.data.status == 'error') {
                    localStorage.clear()
                    router.push({ name: 'login'})
                } else {
                    commit('setCollectTypes', response.data)
                    commit('setBinFormats', response.data)
                    commit('setAddressTags', response.data)
                    commit('setBreakageTypes', response.data)
                    commit('setCities', response.data)
                    commit('setRequests', response.data)
                }
            })
        }
    }
})
