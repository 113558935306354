<template>
    <a id="viewMapDetails" :href="'https://maps.google.com/maps?q=' + address + ', ' + city" target="_blank">
        <div class="btn light small googleMaps">{{ $t('request.details.actions.googleMaps') }}</div>
        <GmapMap ref="mapRef" map-type-id="roadmap" :center="{lat: latitude + 0.001, lng: longitude}" :zoom="14" :options="mapOptions">
            <gmap-custom-marker :marker="{lat: latitude, lng: longitude}" :clickable="false" :draggable="false" class="marker" :class="[priority == 50 ? 'priority' : priority == 25 ? 'priorityMed' : '']">
                <svg v-if="interventions.length == 1" role="img" class="icon"><use v-if="collectTypesID[0] <= 5" :xlink:href="'/images/symbols.svg#ico-collectType' + collectTypesID[0]"></use><use v-else xlink:href="/images/symbols.svg#ico-collectType5"></use></svg>
                <svg v-else role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-more"></use></svg>
            </gmap-custom-marker>
        </GmapMap>
    </a>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker'

export default {
    name: 'MapBlock',
    components: {
        'gmap-custom-marker': GmapCustomMarker
    },
    props: {
        address: String,
        city: String,
        latitude: Number,
        longitude: Number,
        priority: Number,
        collectTypesID: Array,
        interventions: Array
    },
    data() {
        return {
            mapOptions: {
                draggable: false,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                gestureHandling: 'greedy',
                backgroundColor: '#f4f4f4',
                clickableIcons: false,
                styles: [
                {
                    'featureType': 'poi',
                    'elementType': 'labels',
                    'stylers': [
                    {
                        'visibility': 'off'
                    }
                    ]
                },
                {
                    'featureType': 'transit',
                    'elementType': 'labels',
                    'stylers': [
                    {
                        'visibility': 'off'
                    }
                    ]
                }
                ]
            }
        }
    }
}
</script>
