import cityFilter from './filters/city'
import collectFilter from './filters/collect'
import breakageFilter from './filters/breakage'

class FilterManager {
    constructor() {
        this.cityFilter = cityFilter;
        this.collectFilter = collectFilter;
        this.breakageFilter = breakageFilter;
        this.LIST = null // liste interne temporaire
        this.VUE = null
    }

    setFilter(filter) {
        // 1 - filtrer le villes
        this.cityFilter.setFilter(filter.city)
        // 2 - filtrer les collects
        this.collectFilter.setFilter(filter.collect);
        // 3 - filtrer les bris
        this.breakageFilter.setFilter(filter.breakage);
        // 4 - filtrer l’inspecteur
        this.filter()
    }

    // remettre la liste à zero
    resetList() {
        this.LIST = this.VUE.$store.getters.allRequests // impossible de cloner le tableau les objets sont par reference
        for (let index = 0; index < this.LIST.length; index++) {
            this.LIST[index].flag = 1
        }
    }

    // appiquer le filtre
    filter() {
        // reset de la liste
        this.resetList()
        // filtrer la liste
        this.LIST = this.cityFilter.filter(this.LIST)
        this.LIST = this.collectFilter.filter(this.LIST)
        this.LIST = this.breakageFilter.filter(this.LIST)
        // todo: faire pareil pour les autres


        // commiter le bordel, même si je pense que ça ne sert à rien pour éventuellement trigger kkchose
        this.VUE.$store.dispatch('setRequestList', this.LIST)
    }

    // recuperer le store de vue
    attach(vue) {
        this.VUE = vue
    }

}

export default new FilterManager()
