<template>
    <div class="create">
        <div class="background whiteWhite"></div>
        <NavigationBar color="white" title="Signalement" :enableBack="true" :refresh="false" />
        <div :class="loading ? 'show loading' : 'hide loading'"><div class="spinner"></div></div>
        <div id="formulaire" v-if="isConnected">
            <div class="createRequest">
                <h2 v-if="duplicate !== ''" class="createTitleDuplicate">Dupliqué de la requête #{{duplicate}}</h2>
                <h1>Information</h1>
                <div id="localisation">
                    <div class="contentInCreate">
                        <div class="selectControl">
                            <select id="selectSort" class="select" :class="validateForm.includes('localisation') ? 'errorForm' : ''" name="sort" v-model="city">
                                <option value="city" disabled>Ville *</option>
                                <option :key="city.id" v-for="city in cities" :value="city.city">{{ city.city }}</option>
                            </select>
                            <div class="arrow"><img src="/images/arrow.svg" alt="Arrow"></div>
                        </div>
                        <input v-if="city != 'city'" id="nocivic" :disabled="city == 'city'" :class="validateForm.includes('localisation') ? 'errorForm' : ''" type="number" pattern="\d*" placeholder="No *" v-model="nocivic" @click="autocompleteVisibility = true">
                        <input v-if="city != 'city'" id="street" :disabled="city == 'city'" :class="validateForm.includes('localisation') ? 'errorForm' : ''" type="text" placeholder="Nom de rue *" v-model="street" @click="autocompleteVisibility = true; autocompleteAddress('STREET')">
                        <div class="autocomplete" v-if="autocompleteVisibility">
                            <p v-if="autocompleteList[0] != 'Aucun résultat'" class="title">Résultats</p>
                            <p :key="index" v-for="(element, index) in autocompleteList" @click="autocompleteClicked(element)" :class="[element == 'Aucun résultat' ? 'title' : '', element == 'Chargement...' ? 'title' : '']">{{ element }}</p>
                        </div>
                        <input v-if="city != 'city'" id="apartment" :disabled="city == 'city'" type="number" pattern="\d*" placeholder="Appartement" v-model="apartment">
                    </div>
                </div>
                <div class="informationContainer">
                    <div class="infoBlock editable">
                        <div class="informationBlock">
                            <p class="small">Tags d’adresse</p>
                            <select multiple id="addressTag" @focus="click('addressTag')" v-model="addressTag">
                                <option :key="tag.id" v-for="tag in addressTags" :value="tag.id" :selected="tag.id == addressTag">{{ tag.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="section" id="inspector">
                    <div class="choices checkbox">
                        <label for="inspectorME" class="container">Inspecteur en charge <input type="checkbox" name="inspector" id="inspectorME" @click="selectedInspector()" :value="isInspector" v-model="isInspector"><span class="checkmark"><span class="center"></span></span></label>
                    </div>
                </div>
                <div class="informationContainer">
                    <div class="infoBlock editable">
                        <div class="informationBlock">
                            <p class="small">Statut</p>
                            <select id="currentStatus" @focus="click('currentStatus')" v-model="currentStatus">
                                <option :key="status.value" v-for="status in allStatus" :value="status.value" :selected="status.value == currentStatus">{{ status.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="commentBlock">
                    <div class="commentEditable createView" :class="problemCreatorEmpty ? 'empty' : ''">
                        <p class="small commentTitle">Commentaire administrateur</p>
                        <p id="problemCreator" class="content" contenteditable style="white-space: pre-line" @click="clickCommentCreator()">{{ problemCreator }}</p>
                    </div>
                </div>
                <div class="commentBlock">
                    <div class="commentEditable createView" :class="problemRequestEmpty ? 'empty' : ''">
                        <p class="small commentTitle">Commentaire inspecteur</p>
                        <p id="problemRequest" class="content" contenteditable style="white-space: pre-line" @click="clickCommentRequest()">{{ problemRequest }}</p>
                    </div>
                </div>
                <PictureBlockCreate :sessionID="sessionID" key="0" :requestPictures="pictures" :requestInspectorPictures="pictures" :createRequest="true" />
            </div>
            <div class="createIntervention">
                <div class="createInterventionContainer">
                    <h1>Intervention</h1>
                    <div class="informationContainer">
                        <div class="infoBlock editable">
                            <div class="informationBlock" :class="validateForm.includes('collectType') ? 'errorForm' : ''">
                                <p class="small">{{$t('request.details.informations.collect')}} *</p>
                                <select id="collectType" @focus="click('collectType')" @change="selectedCollect()" v-model="collectType">
                                    <option :key="collect.id" v-for="collect in collectTypes" :value="collect.id" :selected="collect.id == collectType">{{ collect.name }}</option>
                                </select>
                            </div>
                            <div class="informationBlock" :class="validateForm.includes('breakageType') ? 'errorForm' : ''">
                                <p class="small">{{$t('request.details.informations.breakage')}} *</p>
                                <select multiple id="breakageType" @focus="click('breakageType')" v-model="breakageType">
                                    <option :key="breakage.id" v-for="breakage in breakageTypes" :value="breakage.id" :selected="breakage.id == breakageType">{{ breakage.name }}</option>
                                </select>
                            </div>
                            <div class="informationBlock" :class="validateForm.includes('binFormat') ? 'errorForm' : ''">
                                <p class="small">{{$t('request.details.informations.bin.format')}} *</p>
                                <select id="binFormat" @focus="click('binFormat')" v-model="binFormat">
                                    <option :key="bin.id" v-for="bin in binFormats" :value="bin.id" :selected="bin.id == binFormat">{{ bin.name }}</option>
                                </select>
                            </div>
                            <div class="informationBlock" :class="validateForm.includes('binNumber') ? 'errorForm' : ''">
                                <p class="small">{{$t('request.details.informations.bin.number')}} *</p>
                                <input id="binNumber" type="text" v-model="binNumber" @focus="click('binNumber')" autocapitalize="characters" v-on:keypress="isValidChar($event)">
                            </div>
                            <div class="informationBlock" :class="binNumberReplace == '' ? 'empty' : ''">
                                <p class="small">{{$t('request.details.informations.bin.numberReplace')}}</p>
                                <input id="binNumberReplace" type="text" v-model="binNumberReplace" @focus="click('binNumberReplace')" autocapitalize="characters" v-on:keypress="isValidChar($event)">
                            </div>
                            <div class="informationBlock" :class="binQuantity == '' ? 'empty' : ''">
                                <p class="small">{{$t('request.details.informations.bin.quantity')}}</p>
                                <input id="binQuantity" type="number" pattern="\d*" v-model="binQuantity" @focus="click('binQuantity')">
                            </div>
                        </div>
                    </div>
                    <div class="commentBlock">
                        <div class="commentEditable createView" :class="problemInterventionEmpty ? 'empty' : ''">
                            <p class="small commentTitle">Commentaire</p>
                            <p id="problemIntervention" class="content" contenteditable style="white-space: pre-line" @click="clickCommentIntervention()">{{ problemIntervention }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="createRequerant">
                <div class="createRequerantContainer">
                    <h1>Requérant</h1>
                    <div class="section" id="information">
                        <div class="contentInCreate">
                            <input type="text" placeholder="Prénom" v-model="plaintiff_firstname">
                            <input type="text" placeholder="Nom" v-model="plaintiff_lastname">
                            <input type="number" pattern="\d*" placeholder="Téléphone" v-model="plaintiff_phone">
                            <input type="number" pattern="\d*" placeholder="Cellulaire" v-model="plaintiff_mobile">
                            <input type="email" placeholder="Courriel" v-model="plaintiff_email">
                        </div>
                        <div class="choices checkbox">
                            <label for="emailNotification" class="container">Cochez cette case si le requérant désire un suivi automatisé par courriel <input type="checkbox" name="emailNotification" id="emailNotification" v-model="plaintiff_notification"><span class="checkmark"><span class="center"></span></span></label>
                        </div>
                    </div>
                    <div class="btn" @click="createRequest()" :class="lockSubmit ? 'disabled' : ''">Envoyer le signalement</div>
                </div>
            </div>
        </div>
        <Popup />
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'
import axios from 'axios'
import NavigationBar from '@/components/navigationBar.vue'
import Popup from '@/components/popup.vue'
import PictureBlockCreate from '@/components/details/pictureBlockCreate.vue'

export default {
    name: 'Create',
    components: {
        NavigationBar,
        Popup,
        PictureBlockCreate
    },
    data() {
        return {
            duplicate: this.$route.params.duplicate ? this.$route.params.duplicate : '',
            sessionID: "",
            loading: false,
            isConnected: false,
            validateForm: [],
            binFormat: '',
            collectType: '',
            breakageType: [],
            addressTag: [],
            binNumber: '',
            binNumberReplace: '',
            binQuantity: '',
            problemRequest: '',
            problemRequestEmpty: true,
            problemCreator: '',
            problemCreatorEmpty: true,
            problemIntervention: '',
            problemInterventionEmpty: true,
            city: 'city',
            apartment: '',
            nocivic: '',
            street: '',
            plaintiff_lastname: '',
            plaintiff_firstname: '',
            plaintiff_phone: '',
            plaintiff_mobile: '',
            plaintiff_email: '',
            plaintiff_notification: 0,
            isInspector: false,
            autocompleteList: [],
            autocompleteVisibility: false,
            civicAutocompleteDone: false,
            streetAutocompleteDone: false,
            pictures: [],
            inspectorName: localStorage.inspectorName,
            lockSubmit: false,
            allStatus: [
                {
                    value: 'WAITING',
                    name: 'Non-attribuée',
                },
                {
                    value: 'SUSPENDED',
                    name: 'En attente',
                },
                {
                    value: 'IN_PROGRESS',
                    name: 'En traitement',
                },
                {
                    value: 'COMPLETED',
                    name: 'Complétée',
                },
                {
                    value: 'CANCELED',
                    name: 'Annulée',
                }
            ],
            currentStatus: 'WAITING',
        }
    },
    computed: {
        request() {
            return this.$store.getters.selectedRequest(this.$route.params.duplicate)
        },
        isSameInspector() {
            return this.$store.getters.isSameInspector(this.$route.params.duplicate)
        },
        collectTypes() {
            return this.$store.getters.allCollectTypes
        },
        breakageTypes() {
            return this.$store.getters.allBreakageTypes
        },
        binFormats() {
            return this.$store.getters.allBinFormats
        },
        cities() {
            return this.$store.getters.allCities
        },
        addressTags() {
            return this.$store.getters.allAddressTags
        }
    },
    mounted() {
        if (localStorage.token) {
            this.isConnected = true
        } else {
            this.isConnected = false
        }

        this.sessionID = localStorage.getItem('inspectorName').slice(0, 3) + new Date().getDate() + "" + (new Date().getMonth() + 1) + "" + new Date().getFullYear() + "" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds()

        EventBus.$on('loading', result => {
            this.loading = result
        })

        if (this.duplicate !== '') {
            this.city = this.request.city
            this.addressTag = this.request.addressTagsID
            this.nocivic = this.request.addressSplit.civic
            this.apartment = this.request.apartment
            this.street = this.request.addressSplit.street
            this.problemCreator = this.request.noteCreator
            if (this.request.noteCreator !== null) {
                this.problemCreatorEmpty = false
            }
            this.problemRequest = this.request.noteInspector
            if (this.request.noteInspector !== null) {
                this.problemRequestEmpty = false
            }
            this.plaintiff_lastname = this.request.plaintiff.lastname
            this.plaintiff_firstname = this.request.plaintiff.firstname
            this.plaintiff_phone = this.request.plaintiff.phone.replace(/\D/g,'')
            this.plaintiff_mobile = this.request.plaintiff.cellphone.replace(/\D/g,'')
            this.plaintiff_email = this.request.plaintiff.email
            this.plaintiff_notification = this.request.plaintiff.emailNotification
            this.isInspector = this.isSameInspector
        }
    },
    beforeDestroy() {
        EventBus.$off('loading')
    },
    methods: {
        isValidChar(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[A-Z0-9]$/g.test(char)) return true
            else e.preventDefault()
        },
        click(input) {
            if (input == 'binNumber') {
                var el = document.getElementById('binNumber')
                el.focus()
                if (typeof el.selectionStart == "number") {
                    el.selectionStart = el.selectionEnd = el.value.length
                } else if (typeof el.createTextRange != "undefined") {
                    var range = el.createTextRange()
                    range.collapse(false)
                    range.select()
                }
            }
            if (input == 'binNumberReplace') {
                var el2 = document.getElementById('binNumberReplace')
                el2.focus()
                if (typeof el2.selectionStart == "number") {
                    el2.selectionStart = el2.selectionEnd = el2.value.length
                } else if (typeof el2.createTextRange != "undefined") {
                    var range2 = el2.createTextRange()
                    range2.collapse(false)
                    range2.select()
                }
            }
            if (input == 'binQuantity') {
                var el3 = document.getElementById('binQuantity')
                el3.focus()
                if (typeof el3.selectionStart == "number") {
                    el3.selectionStart = el3.selectionEnd = el3.value.length
                } else if (typeof el3.createTextRange != "undefined") {
                    var range3 = el3.createTextRange()
                    range3.collapse(false)
                    range3.select()
                }
            }
            if (input == 'binFormat'){
                var select = document.getElementById('binFormat')
                this.binFormat = parseInt(select.value)
            }
            if (input == 'collectType'){
                var selectCollect = document.getElementById('collectType')
                this.collectType = parseInt(selectCollect.value)
            }
            if (input == 'currentStatus'){
                var selectCurrentStatus = document.getElementById('currentStatus')
                this.currentStatus = selectCurrentStatus.value
            }
            if (input == 'breakageType'){
                var selectBreakage = document.getElementById('breakageType')
                this.breakageType = this.getSelectValues(selectBreakage)
            }
            if (input == 'addressTag'){
                var selectAddress = document.getElementById('addressTag')
                this.addressTag = this.getSelectValues(selectAddress)
            }
        },
        getSelectValues(select) {
            var result = [];
            var options = select && select.options;
            var opt;

            for (var i=0, iLen=options.length; i<iLen; i++) {
                opt = options[i];
                if (opt.selected) {
                    result.push(parseInt(opt.value) || parseInt(opt.text));
                }
            }
            return result;
        },
        clickCommentRequest() {
            this.problemRequestEmpty = false
        },
        clickCommentIntervention() {
            this.problemInterventionEmpty = false
        },
        clickCommentCreator() {
            this.problemCreatorEmpty = false
        },
        selectedInspector() {
            this.isInspector = !this.isInspector
        },
        selectedCollect() {
            if(this.collectType != null) {
                this.selectedBinFormat(this.collectTypes.filter(bin => bin.id == this.collectType)[0].defaultBinFormatId)
            } else {
                this.selectedBinFormat('')
            }
        },
        selectedBinFormat(id) {
            this.binFormat = id
        },
        createRequest() {
            var submitForm = true
            this.loading = true
            this.validateForm = []

            if(this.city == 'city' || this.nocivic == '' || this.street == '') {
                this.validateForm.push('localisation')
                this.scollToBlock("#localisation")
                submitForm = false
                this.loading = false
            }
            if(this.collectType === '') {
                this.validateForm.push('collectType')
                this.scollToBlock("#collectType")
                submitForm = false
                this.loading = false
            }
            if(this.breakageType.length == 0) {
                this.validateForm.push('breakageType')
                this.scollToBlock("#breakageType")
                submitForm = false
                this.loading = false
            }
            if(this.binFormat == '') {
                this.validateForm.push('binFormat')
                this.scollToBlock("#binFormat")
                submitForm = false
                this.loading = false
            }
            if(this.binNumber == '') {
                this.validateForm.push('binNumber')
                this.scollToBlock("#binNumber")
                submitForm = false
                this.loading = false
            }

            if(this.validateForm.length > 0) {
                this.scollToBlock('#' + this.validateForm[0])
            }

            if (submitForm) {
                this.lockSubmit = true
                var payload = {
                    session_key: this.sessionID,
                    collect: this.collectType,
                    breakage: this.breakageType,
                    addressTags: this.addressTag,
                    binformat: this.binFormat,
                    binNumber: this.binNumber,
                    binNumberReplace: this.binNumberReplace,
                    binQty: this.binQuantity,
                    problem: document.getElementById('problemCreator').innerText,
                    commentReport: document.getElementById('problemRequest').innerText,
                    commentIntervention: document.getElementById('problemIntervention').innerText,
                    city: this.city,
                    apartment: this.apartment,
                    nocivic: this.nocivic,
                    street: this.street,
                    plaintiff_lastname: this.plaintiff_lastname,
                    plaintiff_firstname: this.plaintiff_firstname,
                    plaintiff_phone: this.plaintiff_phone,
                    plaintiff_mobile: this.plaintiff_mobile,
                    plaintiff_email: this.plaintiff_email,
                    plaintiff_notification: this.plaintiff_notification,
                    isInspector: this.isInspector,
                    status: this.currentStatus,
                    admin_id: 15
                }
                this.$store.dispatch('createNewRequest', payload)
            }
        },
        scollToBlock(block) {
            var element = document.querySelector(block)
            const top = element.getBoundingClientRect().top + window.pageYOffset - 70

            window.scrollTo({
                top,
                behavior: 'smooth'
            })
        },
        autocompleteAddress(type) {
            this.autocompleteList = ['Chargement...']
            this.autocompleteType = type
            var autocomplete = {
                city: this.city,
                civic: this.nocivic,
                street: this.street,
                return: this.autocompleteType
            }
            axios.post('https://roussillon.dev/api/v1/address/autocomplete', null, { headers: { token: localStorage.token }, data: { payload: JSON.stringify(autocomplete) } }).then(response => {
                if (response.data.status == 'error') {
                    EventBus.$emit('openPopup', {'type': 'alert', 'title': 'Erreur', 'description': response.data.message, 'actions': ['Ok'] })
                } else {
                    this.autocompleteList = response.data.data
                    if (this.autocompleteList.length == 0) {
                        this.autocompleteList = ['Aucun résultat']
                    }
                }
            })
        },
        autocompleteClicked(element) {
            this.autocompleteList = []
            this.autocompleteVisibility = false

            if(this.autocompleteType == 'CIVIC') {
                this.nocivic = element
            }
            if(this.autocompleteType == 'STREET') {
                this.street = element
            }
        }
    },
    watch: {
        nocivic() {
            this.autocompleteAddress('CIVIC')
        },
        street() {
            this.autocompleteAddress('STREET')
        },
        isInspector() {
            if(this.isInspector) {
                if (this.currentStatus == 'WAITING') {
                    this.currentStatus = 'IN_PROGRESS'
                }
            } else {
                this.currentStatus = 'WAITING'
            }
        },
        currentStatus() {
            if(this.currentStatus == 'WAITING') {
                this.isInspector = false
            }
            if(this.currentStatus == 'SUSPENDED') {
                this.isInspector = true
            }
            if(this.currentStatus == 'IN_PROGRESS') {
                this.isInspector = true
            }
            if(this.currentStatus == 'COMPLETED') {
                this.isInspector = true
            }
            if(this.currentStatus == 'CANCELED') {
                this.isInspector = true
            }
        }
    }
}
</script>
