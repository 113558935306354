<template>
    <viewer :images="pictures" :options="options">

        <h2 v-if="picturesUser.length" class="pictureBlock-userTitle">Requérant</h2>

        <div v-if="picturesUser.length" class="pictureBlock">
            <div class="pictureContainer" :key="index" v-for="(picture, index) in picturesUser">
                <div class="pictureRemove" @click="removePicture(index, picturesUser[index].pictureID)" v-if="status == 'IN_PROGRESS' && isSameInspector && picture.isDeletable">
                    <svg role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-close"></use></svg>
                </div>
                <img :src="picture.image" :data-src="picture.imageFull">
            </div>
        </div>

        <h2 v-if="(status == 'IN_PROGRESS' && isSameInspector) || pictures.length" class="pictureBlock-inspectorTitle">Inspecteur</h2>
        
        <div v-if="(status == 'IN_PROGRESS' && isSameInspector) || pictures.length" class="pictureBlock">
            <div class="pictureContainer" :key="index" v-for="(picture, index) in pictures">
                <div class="pictureRemove" @click="removePicture(index, pictures[index].pictureID)" v-if="status == 'IN_PROGRESS' && isSameInspector && picture.isDeletable">
                    <svg role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-close"></use></svg>
                </div>
                <img :src="picture.image" :data-src="picture.imageFull">
            </div>

            <div class="pictureAdd" v-if="createRequest || (status == 'IN_PROGRESS' && isSameInspector)">
                <input id="uploadPicture" type="file" name="myPhoto" accept="image/*" @change="addPicture($event)" />
                <svg role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-add"></use></svg>
            </div>
        </div>
        
    </viewer>
</template>

<script>
import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import { EventBus } from '@/classes/eventBus.js'

Vue.use(Viewer)

export default {
    name: 'PictureBlock',
    props: {
        requestPictures: Array,
        requestInspectorPictures: Array,
        createRequest: Boolean
    },
    data() {
        return {
            pictures: [],
            picturesUser: [],
            picturesClicked: [],
            pictureToDelete: Object,
            options: {
                'url': 'data-src',
                'button': false,
                'navbar': true,
                'title': false,
                'toolbar': false,
                'tooltip': false,
                'movable': true,
                'zoomable': true,
                'rotatable': true,
                'scalable': true,
                'transition': true,
                'fullscreen': true,
                'keyboard': true
            }
        }
    },
    computed: {
        isSameInspector() {
            return this.$store.getters.isSameInspector(this.$route.params.requestID)
        },
        status() {
            return this.$store.getters.requestStatus(this.$route.params.requestID)
        }
    },
    mounted() {
        if (this.requestPictures) {
            for(var i = 0; i < this.requestPictures.length; i++) {
                this.picturesUser.push({image: this.requestPictures[i].thumbPath, imageFull: this.requestPictures[i].path, pictureID: this.requestPictures[i].id, isDeletable: false})
            }
        }
        if (this.requestInspectorPictures) {
            for(var j = 0; j < this.requestInspectorPictures.length; j++) {
                this.pictures.push({image: this.requestInspectorPictures[j].thumbPath, imageFull: this.requestInspectorPictures[j].path, pictureID: this.requestInspectorPictures[j].id, isDeletable: true})
            }
        }
        EventBus.$on('removePicture', result => {
            if (result) {
                this.removePictureAccepted(this.pictureToDelete)
            }
        })
    },
    beforeDestroy() {
        EventBus.$off('removePicture')
    },
    methods: {
        addPicture(e) {
            const file = e.target.files[0]
            if (!file) { return }

            this.$store.dispatch('setNewImage', { reportID: this.$route.params.requestID, file: file })
        },
        removePicture(index, pictureID) {
            this.pictureToDelete = {index: index, pictureID: pictureID}
            EventBus.$emit('openPopup', {'type': 'confirmation', 'title': 'Voulez-vous supprimer la photo?', 'description': 'Cette action est irréversible.', 'actions': this.$t('navigationBar.back.actions'), 'event': 'removePicture' })
        },
        removePictureAccepted(picture) {
            this.$delete(this.pictures, picture.index)
            this.$store.dispatch('removePicture', { reportID: this.$route.params.requestID, pictureID: picture.pictureID })
        }
    }
}
</script>