<template>
    <div id="navigationBar" :class="color">
        <a v-if="refresh" @click="refreshApp()" class="navigationBarItem refresh" :class="[this.$parent.refreshInProgress ? 'roll' : '']"><svg role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-refresh"></use></svg></a>
        <a v-if="enableBack" @click="back()" class="navigationBarItem"><svg role="img" class="icon back"><use xlink:href="/images/symbols.svg#ico-back"></use></svg></a>
        <p class="viewTitle" :class="[enableBack || refresh ? '' : 'noBack']">{{ title }}</p>
        <a v-if="duplicate" @click="duplicateRequest()" class="navigationBarItem">
            <svg role="img" class="icon duplicate"><use xlink:href="/images/symbols.svg#ico-duplicate"></use></svg>
        </a>
        <a v-else @click="logout()" class="navigationBarItem">
            <svg role="img" class="icon logout"><use xlink:href="/images/symbols.svg#ico-logout"></use></svg>
        </a>
        <div :class="'corners ' + color"></div>
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'

export default {
    name: 'NavigationBar',
    props: {
        color: String,
        title: String,
        enableBack: Boolean,
        refresh: Boolean,
        origin: String,
        duplicate: String,
    },
    mounted() {
        EventBus.$on('back', result => {
            if (result) {
                this.$router.back()
            }
        })
        EventBus.$on('logout', result => {
            if (result) {
                this.$store.dispatch('logout')
                this.$router.push({ name: 'login' })
            }
        })
    },
    beforeDestroy() {
        EventBus.$off('back')
        EventBus.$off('logout')
    },
    methods: {
        back() {
            if (this.origin == 'filters') {
                EventBus.$emit('filtersBack', true)
            } else if (this.origin == 'list') {
                this.$router.push({ name: 'home', params: { pageView: 'list' } })
            } else if (this.origin == 'map') {
                this.$router.push({ name: 'home', params: { pageView: 'map' } })
            } else {
                this.$router.back()
            }
        },
        refreshApp() {
            EventBus.$emit('refresh', true)
        },
        duplicateRequest() {
            this.$router.push({ name: 'create', params: { duplicate: this.duplicate } })
        },
        logout() {
            EventBus.$emit('openPopup', {'type': 'confirmation', 'title': this.$t('navigationBar.logout.title'), 'description': this.$t('navigationBar.logout.description.normal'), 'actions': this.$t('navigationBar.logout.actions'), 'event': 'logout' })
        }
    }
}
</script>
