<template>
    <div id="popup" v-if="popupActive">
        <div class="popupContainer" v-if="type == 'prompt'">
            <h2 class="title">{{ title }}</h2>
            <div class="choices" :class="[willCancel ? '' : 'spacer']">
                <label :key="index" v-for="(item, index) in prompt" class="container" :for="index" @click="currentStatus(index)">{{ item.title }}
                    <input v-if="index == 0" type="radio" checked name="requestStatus" :id="index">
                    <input v-else type="radio" name="requestStatus" :id="index">
                    <span class="checkmark"><span class="center"></span></span>
                </label>
            </div>
            <div class="cancelComment" :class="[willCancel ? 'spacer' : ' hide']">
                <div class="inputBlock">
                    <svg :class="[hasBeenClicked ? '' : 'addPlus']" role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-add"></use></svg>
                    <input id="addComment" v-model="cancelComment" @click="hasBeenClicked = true" @focus="checkEmpty" @blur="checkEmpty" :class="[hasBeenClicked ? '' : 'addPlus']" type="text" :placeholder="$t('request.details.actions.popup.IN_PROGRESS.cancel.placeholder')" />
                </div>
            </div>
            <div class="buttons">
                <div id="cancel" @click="close()">{{ actions[0] }}</div>
                <div id="accept" @click="acceptPrompt()">{{ actions[1] }}</div>
            </div>
        </div>
        <div class="popupContainer" v-if="type == 'alert'" :class="type">
            <h2 class="title">{{ title }}</h2>
            <p class="desc">{{ description }}</p>
            <div class="buttons">
                <div id="accept" @click="close()">{{ actions[0] }}</div>
            </div>
        </div>
        <div class="popupContainer" v-if="type == 'confirmation'" :class="type">
            <h2 class="title">{{ title }}</h2>
            <p class="desc">{{ description }}</p>
            <div class="buttons">
                <div id="cancel" @click="close()">{{ actions[0] }}</div>
                <div id="accept" @click="accept()">{{ actions[1] }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'

export default {
    name: 'Popup',
    data() {
        return {
            popupActive: false,
            type: '',
            title: '',
            description: '',
            actions: [],
            event: '',
            reloadPage: false,
            prompt: [
                {
                    title: this.$t('request.details.actions.popup.IN_PROGRESS.prompt[0]')
                },
                {
                    title: this.$t('request.details.actions.popup.IN_PROGRESS.prompt[1]')
                },
                {
                    title: this.$t('request.details.actions.popup.IN_PROGRESS.prompt[2]')
                },
                {
                    title: this.$t('request.details.actions.popup.IN_PROGRESS.prompt[3]')
                }
            ],
            willCancel: false,
            status: 'COMPLETED',
            cancelComment: '',
            hasBeenClicked: false
        }
    },
    mounted() {
        EventBus.$on('openPopup', result => {
            this.popupActive = true
            this.type = result.type
            this.title = result.title
            this.description = result.description
            this.actions = result.actions
            this.event = result.event
            this.reloadPage = result.reloadPage
        })
    },
    beforeDestroy() {
        EventBus.$off('openPopup')
    },
    methods: {
        currentStatus(element) {
            switch(element) {
                case 0:
                    this.willCancel = false
                    this.cancelComment = ''
                    this.hasBeenClicked = false
                    this.status = 'COMPLETED'
                    break
                case 1:
                    this.willCancel = false
                    this.cancelComment = ''
                    this.hasBeenClicked = false
                    this.status = 'SUSPENDED'
                    break
                case 2:
                    this.willCancel = false
                    this.cancelComment = ''
                    this.hasBeenClicked = false
                    this.status = 'WAITING'
                    break
                case 3:
                    this.willCancel = true
                    this.status = 'CANCELED'
                    break
                default:
                    this.willCancel = false
                    this.cancelComment = ''
                    this.hasBeenClicked = false
                    this.status = 'COMPLETED'
                    break
            }
        },
        checkEmpty(event) {
            if (event.target.value != '') {
                if (event.target.classList.contains('empty')) {
                    event.target.classList.remove('empty')
                }
            }
        },
        accept() {
            EventBus.$emit(this.event, true)
            this.close()
        },
        acceptPrompt() {
            if(this.status == 'COMPLETED') {
                EventBus.$emit(this.event, {status: this.status, inspectorName: localStorage.inspectorName})
                this.close()
            } else if(this.status == 'SUSPENDED') {
                EventBus.$emit(this.event, {status: this.status, inspectorName: localStorage.inspectorName})
                this.close()
            } else if(this.status == 'WAITING') {
                EventBus.$emit(this.event, {status: this.status, inspectorName: null})
                this.close()
            } else if(this.status == 'CANCELED') {
                EventBus.$emit(this.event, {status: this.status, inspectorName: localStorage.inspectorName, noteCancelation: this.cancelComment})
                this.close()
            }
        },
        close() {
            this.popupActive = false
            this.willCancel = false
            this.hasBeenClicked = false
            this.cancelComment = ''
            this.status = 'COMPLETED'
            if (this.reloadPage) {
                this.$router.go()
            }
        }
    }
}
</script>
