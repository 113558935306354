<template>
    <div class="infoBlock editable" :class="[hasClicked ? 'editing' : '']">
        <div v-if="isSameInspector && status == 'IN_PROGRESS'" class="informationBlock" @click="click()" :class="[binNumber == '' ? 'empty' : '', !isSameInspector ? 'notEditable' : '']">
            <p class="small" :class="[status == 'IN_PROGRESS' && canEdit ? 'canEdit' : '']">{{ description }}</p>
            <input :id="'input' + intervention.id" v-if="type == 'input'" type="text" v-model="binNumber" @focus="click('binNumber'); clickedInput = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" autocapitalize="characters" v-on:keypress="isValidChar($event)" v-on:keyup.enter="save()">
            <input :id="'inputReplace' + intervention.id" v-if="type == 'inputReplace'" type="text" v-model="binNumberReplace" @focus="click('binNumberReplace'); clickedInputReplace = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" autocapitalize="characters" v-on:keypress="isValidChar($event)" v-on:keyup.enter="save()">
            <input :id="'inputQuantity' + intervention.id" v-if="type == 'inputQuantity'" type="number" pattern="\d*" v-model="binQuantity" @focus="click('binQuantity'); clickedInputQuantity = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-on:keyup.enter="save()">
            <select v-else-if="type == 'select'" :id="'select' + intervention.id" @focus="click(); clickedSelect = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-model="binFormat">
                <option :key="bin.id" v-for="bin in binFormats" :value="bin.id" :selected="bin.id == binFormat">{{ bin.name }}</option>
            </select>
            <select v-else-if="type == 'selectCollect'" :id="'selectCollect' + intervention.id" @focus="click(); clickedSelectCollect = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-model="collectType">
                <option :key="collect.id" v-for="collect in collectTypes" :value="collect.id" :selected="collect.id == collectType">{{ collect.name }}</option>
            </select>
            <select multiple v-else-if="type == 'selectBreakage'" :id="'selectBreakage' + intervention.id" @focus="click(); clickedSelectBreakage = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-model="breakageType">
                <option :key="breakage.id" v-for="breakage in breakageTypes" :value="breakage.id" :selected="breakage.id == breakageType">{{ breakage.name }}</option>
            </select>
        </div>
        <div v-else-if="status != 'IN_PROGRESS' && (type == 'input' || type == 'inputReplace' || type == 'inputQuantity') || type == 'select'" class="informationBlock" @click="click()" :class="[binNumber == '' ? 'empty' : '', binNumberReplace == '' ? 'empty' : '', binQuantity == '' ? 'empty' : '', !isSameInspector ? 'notEditable' : '', status != 'IN_PROGRESS' ? 'notEditable' : '']">
            <p class="small" :class="[status == 'IN_PROGRESS' && canEdit ? 'canEdit' : '']">{{ description }}</p>
            <input :id="'input' + intervention.id" v-if="type == 'input'" type="text" v-model="binNumber" @focus="click('binNumber'); clickedInput = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" autocapitalize="characters" v-on:keypress="isValidChar($event)" v-on:keyup.enter="save()">
            <input :id="'inputReplace' + intervention.id" v-if="type == 'inputReplace'" type="text" v-model="binNumberReplace" @focus="click('binNumberReplace'); clickedInputReplace = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" autocapitalize="characters" v-on:keypress="isValidChar($event)" v-on:keyup.enter="save()">
            <input :id="'inputQuantity' + intervention.id" v-if="type == 'inputQuantity'" type="number" pattern="\d*" v-model="binQuantity" @focus="click('binQuantity'); clickedInputQuantity = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-on:keyup.enter="save()">
            <select v-else-if="type == 'select'" :id="'select' + intervention.id" @focus="click(); clickedSelect = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-model="binFormat">
                <option :key="bin.id" v-for="bin in binFormats" :value="bin.id" :selected="bin.id == binFormat">{{ bin.name }}</option>
            </select>
            <select v-else-if="type == 'selectCollect'" :id="'selectCollect' + intervention.id" @focus="click(); clickedSelectCollect = true" :disabled="status != 'IN_PROGRESS' || canEdit == false" v-model="collectType">
                <option :key="collect.id" v-for="collect in collectTypes" :value="collect.id" :selected="collect.id == collectType">{{ collect.name }}</option>
            </select>
        </div>

        <div class="buttons" v-if="hasClicked">
            <div id="cancel" @click="cancel()">{{ $t('request.details.actions.cancel') }}</div>
            <div id="accept" @click="save()">{{ $t('request.details.actions.accept') }}</div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'

export default {
    name: 'InformationBlockEditable',
    props: {
        description: String,
        value: String,
        intervention: Object,
        type: String,
        status: String,
        canEdit: Boolean,
        origin: String
    },
    computed: {
        isSameInspector() {
            return this.$store.getters.isSameInspector(this.$route.params.requestID)
        },
        binFormats() {
            return this.$store.getters.allBinFormats
        },
        collectTypes() {
            return this.$store.getters.allCollectTypes
        },
        breakageTypes() {
            return this.$store.getters.allBreakageTypes
        }
    },
    data() {
        return {
            hasClicked: false,
            binFormat: this.intervention.binFormat,
            collectType: this.intervention.collectTypeID,
            breakageType: this.intervention.breakageTypes,
            binNumber: this.value,
            binNumberReplace: this.value,
            binQuantity: this.value,
            binFormatTemp: this.intervention.binFormat,
            collectTypeTemp: this.intervention.collectTypeID,
            breakageTypeTemp: this.intervention.breakageTypes,
            binNumberTemp: this.value,
            binNumberReplaceTemp: this.value,
            binQuantityTemp: this.value,
            clickedInput: false,
            clickedInputReplace: false,
            clickedInputQuantity: false,
            clickedSelect: false,
            clickedSelectCollect: false,
            clickedSelectBreakage: false
        }
    },
    watch: {
        binNumber(val) {
            this.binNumber = val.replace(/\W/g, "")
        },
        binNumberReplace(val) {
            this.binNumberReplace = val.replace(/\W/g, "")
        },
    },
    methods: {
        isValidChar(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[A-Z0-9]$/g.test(char)) return true
            else e.preventDefault()
        },
        click(input) {
            if(this.canEdit) {
                if(this.status == 'IN_PROGRESS' && !this.hasClicked) {
                    this.hasClicked = true
                    EventBus.$emit('isEditing', 1)
                }
                if (this.type == 'input') {
                    if (input == 'binNumber') {
                        var el = document.getElementById('input' + this.intervention.id)
                        el.focus()
                        if (typeof el.selectionStart == "number") {
                            el.selectionStart = el.selectionEnd = el.value.length
                        } else if (typeof el.createTextRange != "undefined") {
                            var range = el.createTextRange()
                            range.collapse(false)
                            range.select()
                        }
                    }
                    if (input == 'binNumberReplace') {
                        var el2 = document.getElementById('inputReplace' + this.intervention.id)
                        el2.focus()
                        if (typeof el2.selectionStart == "number") {
                            el2.selectionStart = el2.selectionEnd = el2.value.length
                        } else if (typeof el2.createTextRange != "undefined") {
                            var range2 = el2.createTextRange()
                            range2.collapse(false)
                            range2.select()
                        }
                    }
                }
                if (this.type == 'inputQuantity') {
                    if (input == 'binQuantity') {
                        var el3 = document.getElementById('inputQuantity' + this.intervention.id)
                        el3.focus()
                        if (typeof el3.selectionStart == "number") {
                            el3.selectionStart = el3.selectionEnd = el3.value.length
                        } else if (typeof el3.createTextRange != "undefined") {
                            var range3 = el3.createTextRange()
                            range3.collapse(false)
                            range3.select()
                        }
                    }
                }
            }
        },
        save() {
            this.hasClicked = false

            if (this.clickedInput){
                if (this.binNumber == this.intervention.binNumber) {
                    this.binNumberTemp = this.binNumber
                    this.cancel()
                } else if (this.binNumber == '' && this.binNumberTemp != '') {
                    this.hasClicked = false
                    var el10 = document.getElementById('input' + this.intervention.id)
                    this.binNumber = el10.value
                    this.binNumberTemp = el10.value

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binNumber: this.binNumber})
                    EventBus.$emit('isEditing', 0)
                } else {
                    this.hasClicked = false
                    var el11 = document.getElementById('input' + this.intervention.id)
                    this.binNumber = el11.value
                    this.binNumberTemp = el11.value

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binNumber: this.binNumber})
                    EventBus.$emit('isEditing', 0)
                }
            }
            if (this.clickedInputReplace){
                if (this.binNumberReplace == this.intervention.binNumberReplace) {
                    this.binNumberReplaceTemp = this.binNumberReplace
                    this.cancel()
                } else if (this.binNumberReplace == '' || this.binNumberReplaceTemp != '') {
                    this.hasClicked = false
                    var el20 = document.getElementById('inputReplace' + this.intervention.id)
                    this.binNumberReplace = el20.value
                    this.binNumberReplaceTemp = el20.value

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binNumberReplace: this.binNumberReplace})
                    EventBus.$emit('isEditing', 0)
                } else {
                    this.hasClicked = false
                    var el21 = document.getElementById('inputReplace' + this.intervention.id)
                    this.binNumberReplace = el21.value
                    this.binNumberReplaceTemp = el21.value

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binNumberReplace: this.binNumberReplace})
                    EventBus.$emit('isEditing', 0)
                }
            }
            if (this.clickedInputQuantity){
                if (this.binQuantity == this.intervention.binQuantity) {
                    this.binQuantityTemp = this.binQuantity
                    this.cancel()
                } else if (this.binQuantity == '' || this.binQuantityTemp != '') {
                    this.hasClicked = false
                    var el30 = document.getElementById('inputQuantity' + this.intervention.id)
                    this.binQuantity = el30.value
                    this.binQuantityTemp = el30.value

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binQty: parseInt(this.binQuantity)})
                    EventBus.$emit('isEditing', 0)
                } else {
                    this.hasClicked = false
                    var el31 = document.getElementById('inputQuantity' + this.intervention.id)
                    this.binQuantity = el31.value
                    this.binQuantityTemp = el31.value

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binQuantity: parseInt(this.binQuantity)})
                    EventBus.$emit('isEditing', 0)
                }
            }
            if (this.clickedSelect){
                if (this.binFormat == this.intervention.binFormat) {
                    this.binFormatTemp = this.binFormat
                    this.cancel()
                } else {
                    this.hasClicked = false
                    var select = document.getElementById('select' + this.intervention.id)
                    this.binFormat = parseInt(select.value)
                    this.binFormatTemp = parseInt(select.value)

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, binFormat: this.binFormat})
                    EventBus.$emit('isEditing', 0)
                }
            }
            if (this.clickedSelectCollect){
                if (this.collectType == this.intervention.collectType) {
                    this.collectTypeTemp = this.collectType
                    this.cancel()
                } else {
                    this.hasClicked = false
                    var selectCollect = document.getElementById('selectCollect' + this.intervention.id)
                    this.collectType = parseInt(selectCollect.value)
                    this.collectTypeTemp = parseInt(selectCollect.value)

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, collectId: this.collectType})
                    EventBus.$emit('isEditing', 0)
                }
            }
            if (this.clickedSelectBreakage){
                if (this.breakageType == this.intervention.breakageTypes) {
                    this.breakageTypeTemp = this.breakageType
                    this.cancel()
                } else {
                    this.hasClicked = false
                    var selectBreakage = document.getElementById('selectBreakage' + this.intervention.id)
                    this.breakageType = this.getSelectValues(selectBreakage)
                    this.breakageTypeTemp = this.getSelectValues(selectBreakage)

                    EventBus.$emit(this.origin + 'WasEdited', {interventionID: this.intervention.id, id: this.$route.params.requestID, breakage: this.breakageType})
                    EventBus.$emit('isEditing', 0)
                }
            }
        },
        cancel() {
            this.hasClicked = false
            if (this.clickedInput) {
                this.binNumber = this.binNumberTemp
            }
            if (this.clickedInputReplace) {
                this.binNumberReplace = this.binNumberReplaceTemp
            }
            if (this.clickedInputQuantity) {
                this.binQuantity = this.binQuantityTemp
            }
            if (this.clickedSelect) {
                this.binFormat = this.binFormatTemp
            }
            if (this.clickedSelectCollect) {
                this.collectType = this.collectTypeTemp
            }
            if (this.clickedSelectBreakage) {
                this.breakageType = this.breakageTypeTemp
            }
            EventBus.$emit('isEditing', 0)
        },
        getSelectValues(select) {
            var result = [];
            var options = select && select.options;
            var opt;

            for (var i=0, iLen=options.length; i<iLen; i++) {
                opt = options[i];
                if (opt.selected) {
                    result.push(parseInt(opt.value) || parseInt(opt.text));
                }
            }
            return result;
        }
    }
}
</script>
