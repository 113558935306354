<template>
    <div class="request">
        <router-link :to="{ name: 'requestDetails', params: { requestID: request.reportID, origin: origin }}">
            <StatusBlock :status="request.status" :collectTypes="request.collectTypesID" :reportID="request.reportID" :key="index" v-for="(intervention, index) in request.interventions" :intervention="intervention" />
            <div class="middle">
                <div class="information">
                    <p class="small" v-if="request.plannedFor">Date de service: {{ getReportDate(request.plannedFor) }}</p>
                    <div class="city">
                        <svg v-if="request.priority == 50" role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-priority"></use></svg>
                        <svg v-if="request.priority == 25" role="img" class="iconMed"><use xlink:href="/images/symbols.svg#ico-priority"></use></svg>
                        <h2 :class="[request.priority == 50 ? 'priority' : request.priority == 25 ? 'priorityMed' : '']">{{ request.city }}</h2>
                    </div>
                    <p class="small desc">{{ getBreakageTypes(request.breakageTypes) }}</p>
                    <p class="small">{{ request.address }}</p>
                </div>
                <div class="circle"><svg role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-arrow"></use></svg></div>
            </div>
        </router-link>
        <a v-if="request.status == 'WAITING'" class="bottom add" @click="addRequest()">{{ $t('request.addRequest.title') }}</a>
        <router-link v-else-if="request.status != 'WAITING'" :to="{ name: 'requestDetails', params: { requestID: request.reportID, origin: origin }}">
            <div class="bottom inspector">{{ $t('request.inspector') }} <span>{{ request.inspectorName }}</span></div>
        </router-link>
    </div>
</template>

<script>
import StatusBlock from '@/components/statusBlock.vue'

export default {
    name: 'Request',
    components: {
        StatusBlock
    },
    props: {
        request: Object,
        origin: String
    },
    computed: {
        breakageTypes() {
            return this.$store.getters.allBreakageTypes
        }
    },
    methods: {
        getReportDate(date) {
            var day = new Date(date).getDate()
            var month = this.$t('request.details.informations.months[' + new Date(date).getMonth() + ']')
            var year = new Date(date).getFullYear()

            return day + ' ' + month + ' ' + year
        },
        getBreakageTypes(breakage) {
            var breakageTypesToString = []
            for (var i = 0; i < breakage.length; i++) {
                breakageTypesToString.push(this.breakageTypes.find(x => x.id === breakage[i]).name)
            }
            return breakageTypesToString.join(', ')
        },
        addRequest() {
            this.$store.dispatch('setRequestStatus', {id: this.request.reportID, status: 'IN_PROGRESS', inspectorName: localStorage.inspectorName})
        }
    }
}
</script>
