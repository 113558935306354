<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import filterManager from '@/classes/filterManager.js'
import { EventBus } from '@/classes/eventBus.js'

export default {
    name: 'App',
    created() {
        // envoyer l’instance de vue au filter manager
        filterManager.attach(this)
    },
    mounted() {
        if (localStorage.token) {
            if (this.$store.getters.isResults == false) {
                this.$store.dispatch('getRequests')
            }
        }
        EventBus.$on('needRefresh', result => {
            if (result) {
                this.$store.dispatch('getRequests')
            }
        })
    }
}
</script>

<style lang="scss">
    @import '@/assets/styles/style.scss';
</style>
