<template>
    <div class="tagBlock">
        <p :key="index" v-for="(tag, index) in tags" class="small tag">{{ metadata.find(x => x.id === tag).name }}</p>
    </div>
</template>

<script>
export default {
    name: 'TagBlock',
    props: {
        metadata: Array,
        tags: Array
    }
}
</script>