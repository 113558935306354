<template>
    <div id="requestDetails">
        <div class="background greyWhite"></div>
        <NavigationBar color="grey" :title="$t('request.details.title')" enableBack :refresh="false" :origin="origin" :duplicate="requestID" />
        <div id="request" v-if="request">
            <div id="informations">
                <StatusBlock :collectTypes="request.collectTypesID" :reportID="request.reportID" />
                <div class="city">
                    <svg v-if="request.priority == 50" role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-priority"></use></svg>
                    <svg v-if="request.priority == 25" role="img" class="iconMed"><use xlink:href="/images/symbols.svg#ico-priority"></use></svg>
                    <h1 :class="[request.priority == 50 ? 'priority' : request.priority == 25 ? 'priorityMed' : '']">{{ request.city }}</h1>
                </div>
                <h2 class="address">{{ request.address }}</h2>
                <TagBlock :metadata="addressTags" :tags="request.addressTagsID" />
                <CommentBlock :noteCreator="request.noteCreator" :noteInspector="request.noteInspector" :commentID="parseInt(requestID)" origin="request" />
                <a v-if="request.latitude != null || request.longitude != null" class="btn light small" :href="'https://maps.google.com/maps?q=' + request.address + ', ' + request.city" target="_blank">{{ $t('request.details.actions.googleMaps') }}</a>
                <TopInformations :reportAt="request.reportAt" :plannedFor="request.plannedFor" :apartment="request.apartment" :inspectorName="request.inspectorName" :plaintiff="request.plaintiff" :administrator="request.administrator" :source="request.source" />
                <PictureBlock :key="request.pictures.length" :requestPictures="request.pictures" :requestInspectorPictures="request.picturesInspector" />
            </div>
            <div id="interventions" :class="[isSameInspector || request.status == 'WAITING' ? '' : 'noPadding', request.latitude == null || request.longitude == null ? 'lonely' : '']">
                <Intervention :key="index" v-for="(intervention, index) in request.interventions" :intervention="intervention" :status="request.status" />
                <MapBlock v-if="request.latitude != null || request.longitude != null" :address="request.address" :city="request.city" :latitude="parseInt(request.latitude)" :longitude="parseInt(request.longitude)" :priority="request.priority" :collectTypesID="request.collectTypesID" :interventions="request.interventions" />
            </div>
            <div v-observe-visibility="visibilityChanged"></div>
            <div class="buttonsB" :class="[isVisible ? 'removeGradient' : '']" v-if="(!hideRequestButtons && isSameInspector) || (!hideRequestButtons && request.status == 'WAITING')">
                <div v-if="request.status == 'WAITING' || request.status == 'SUSPENDED' || request.status == 'COMPLETED' || request.status == 'CANCELED'" @click="addRequest()" class="btn">{{ $t('request.details.actions.status.' + request.status) }}</div>
                <div v-else @click="editRequest()" class="btn">{{ $t('request.details.actions.status.' + request.status) }}</div>
            </div>
        </div>
        <Popup />
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'
import CommentBlock from '@/components/details/commentBlock.vue'
import Intervention from '@/components/details/intervention.vue'
import MapBlock from '@/components/details/mapBlock.vue'
import NavigationBar from '@/components/navigationBar.vue'
import PictureBlock from '@/components/details/pictureBlock.vue'
import Popup from '@/components/popup.vue'
import StatusBlock from '@/components/statusBlock.vue'
import TagBlock from '@/components/details/tagBlock.vue'
import TopInformations from '@/components/details/topInformations.vue'

import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

export default {
    name: 'RequestDetails',
    components: {
        NavigationBar,
        Intervention,
        Popup,
        CommentBlock,
        TagBlock,
        StatusBlock,
        MapBlock,
        PictureBlock,
        TopInformations
    },
    data() {
        return {
            origin: this.$route.params.origin,
            requestID: this.$route.params.requestID,
            hideRequestButtons: false,
            inspectorSaved: 0,
            isVisible: false
        }
    },
    computed: {
        addressTags() {
            return this.$store.getters.allAddressTags
        },
        collectTypes() {
            return this.$store.getters.allCollectTypes
        },
        request() {
            return this.$store.getters.selectedRequest(this.$route.params.requestID)
        },
        isSameInspector() {
            return this.$store.getters.isSameInspector(this.$route.params.requestID)
        }
    },
    mounted() {
        window.scrollTo(0, 0)

        EventBus.$on('isEditing', result => {
            this.hideRequestButtons = result
        })

        EventBus.$on('popupAccept', result => {
            this.$store.dispatch('setRequestStatus', {id: this.$route.params.requestID, status: result.status, inspectorName: result.inspectorName, noteCancelation: result.noteCancelation})
        })

        EventBus.$on('requestWasEdited', result => {
            if (result) {
                this.$store.dispatch('saveRequest', result)
            }
        })

        EventBus.$on('interventionWasEdited', result => {
            if (result) {
                this.$store.dispatch('saveIntervention', result)
            }
        })
    },
    beforeDestroy() {
        EventBus.$off('isEditing')
        EventBus.$off('popupAccept')
        EventBus.$off('requestWasEdited')
        EventBus.$off('interventionWasEdited')
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible
        },
        addRequest() {
            this.$store.dispatch('setRequestStatus', {id: this.$route.params.requestID, status: 'IN_PROGRESS', inspectorName: localStorage.inspectorName})
        },
        editRequest() {
            EventBus.$emit(
                'openPopup', {
                    'type': 'prompt',
                    'title': this.$t('request.details.actions.popup.IN_PROGRESS.title'),
                    'description': this.$t('request.details.actions.popup.IN_PROGRESS.description'),
                    'actions': this.$t('request.details.actions.popup.IN_PROGRESS.actions'),
                    'event': 'popupAccept',
                }
            )
        }
    }
}
</script>
