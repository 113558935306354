<template>
    <div class="commentBlock">
        <div v-if="noteCreator != null" class="comment">
            <p class="small commentTitle">{{ $t('request.details.comments.creator') }}</p>
            <p class="content" style="white-space: pre-line">{{ noteCreator }}</p>
        </div>
        <div v-if="isSameInspector && status == 'IN_PROGRESS' " :class="[(status != 'IN_PROGRESS' && noteInspector != null) || status == 'IN_PROGRESS' && noteInspector != null && !isSameInspector ? '' : '', hasClicked ? 'editing' : '', commentEmpty ? 'empty' : '', inspectorComment == null && origin == 'intervention' ? 'border' : '', status != 'IN_PROGRESS' || !isSameInspector ? 'notEditable' : '']" class="commentEditable">
            <p class="small commentTitle">{{ $t('request.details.comments.inspector') }}</p>
            <p :id="'textarea' + commentID" class="content" :contenteditable="isSameInspector" @click="click()" style="white-space: pre-line">{{ inspectorComment }}</p>
            <div class="buttons" v-if="hasClicked">
                <div id="cancel" @click="close()">{{ $t('request.details.actions.cancel') }}</div>
                <div id="accept" @click="save()">{{ $t('request.details.actions.accept') }}</div>
            </div>
        </div>
        <div v-else-if="inspectorComment != null" :class="[commentEmpty ? 'empty' : '', inspectorComment == null && origin == 'intervention' ? 'border' : '', status != 'IN_PROGRESS' || !isSameInspector ? 'notEditable' : '']" class="commentEditable">
            <p class="small commentTitle">{{ $t('request.details.comments.inspector') }}</p>
            <p :id="'textarea' + commentID" class="content" style="white-space: pre-line">{{ inspectorComment }}</p>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'

export default {
    name: 'CommentBlock',
    props: {
        commentID: Number,
        noteCreator: String,
        noteInspector: String,
        origin: String
    },
    data() {
        return {
            inspectorComment: this.noteInspector,
            hasClicked: false,
            commentBlockHeight: Number,
            textareaHeight: Number,
            commentEmpty: false
        }
    },
    computed: {
        isSameInspector() {
            return this.$store.getters.isSameInspector(this.$route.params.requestID)
        },
        status() {
            return this.$store.getters.requestStatus(this.$route.params.requestID)
        }
    },
    mounted() {
        if (this.inspectorComment == null || this.inspectorComment == '' || this.inspectorComment === '') {
            this.commentEmpty = true
        } else {
            this.commentEmpty = false
        }
    },
    methods: {
        click() {
            if(this.status == 'IN_PROGRESS' && this.isSameInspector && !this.hasClicked) {
                this.hasClicked = true
                this.commentEmpty = false
                EventBus.$emit('isEditing', 1)
            }
        },
        save() {
            var el = document.getElementById('textarea' + this.commentID)
            this.inspectorComment = el.textContent
            if (this.inspectorComment == null || this.inspectorComment == '' || this.inspectorComment === '') {
                this.inspectorComment = null
                EventBus.$emit(this.origin + 'WasEdited', {id: this.$route.params.requestID, interventionID: this.commentID, noteInspector: null})
                this.hasClicked = false
                EventBus.$emit('isEditing', 0)
                this.checkEmpty()
            } else if(this.inspectorComment == this.noteInspector) {
                this.hasClicked = false
                EventBus.$emit('isEditing', 0)
                this.checkEmpty()
            } else {
                EventBus.$emit(this.origin + 'WasEdited', {id: this.$route.params.requestID, interventionID: this.commentID, noteInspector: this.inspectorComment})

                el.textContent = this.inspectorComment
                this.hasClicked = false
                EventBus.$emit('isEditing', 0)
                this.checkEmpty()
            }
        },
        close() {
            var el = document.getElementById('textarea' + this.commentID)
            el.textContent = this.inspectorComment
            this.hasClicked = false
            EventBus.$emit('isEditing', 0)
            this.checkEmpty()
        },
        checkEmpty() {
            var el = document.getElementById('textarea' + this.commentID)
            if (el.textContent == null || el.textContent == '' || el.textContent === '') {
                this.commentEmpty = true
            } else {
                this.commentEmpty = false
            }
        }
    }
}
</script>
