<template>
    <div id="topInformations">
        <div class="requestInfos">
            <div class="block">
                <div class="information">
                    <p class="small">{{ $t('request.details.informations.creationDate') }}</p>
                    <p class="small">{{ getReportDate(reportAt) }}</p>
                </div>
            </div>
            <div class="block" v-if="plannedFor">
                <div class="information">
                    <p class="small">Date de service</p>
                    <p class="small">{{ getReportDate(plannedFor) }}</p>
                </div>
            </div>
            <div class="block" v-if="apartment">
                <div class="information">
                    <p class="small">Appartement</p>
                    <p class="small">{{ apartment }}</p>
                </div>
            </div>
            <div v-if="status != 'WAITING'" class="block">
                <div class="information">
                    <p class="small">{{ $t('request.details.informations.inspector') }}</p>
                    <p class="small">{{ inspectorName }}</p>
                </div>
            </div>
            <div class="block" v-if="administrator">
                <div class="information">
                    <p class="small">{{ $t('request.details.informations.administrator') }}</p>
                    <p class="small">{{ administrator.first_name }} {{ administrator.last_name }}</p>
                </div>
            </div>
            <div class="block" v-if="source">
                <div class="information">
                    <p class="small">{{ $t('request.details.informations.source') }}</p>
                    <p class="small">{{ source }}</p>
                </div>
            </div>
        </div>
        <div class="requerantInfos">
            <h2>Informations sur le requérant</h2>
            <div class="block" v-if="plaintiff.firstname">
                <div class="information">
                    <p class="small">Prénom</p>
                    <p class="small">{{ plaintiff.firstname }}</p>
                </div>
            </div>
            <div class="block" v-if="plaintiff.lastname">
                <div class="information">
                    <p class="small">Nom</p>
                    <p class="small">{{ plaintiff.lastname }}</p>
                </div>
            </div>
            <div class="block" v-if="plaintiff.phone">
                <div class="information">
                    <p class="small">Téléphone</p>
                    <a :href="'tel:' + plaintiff.phone" class="small">{{ plaintiff.phone }}</a>
                </div>
            </div>
            <div class="block" v-if="plaintiff.cellphone">
                <div class="information">
                    <p class="small">Cellulaire</p>
                    <a :href="'tel:' + plaintiff.cellphone" class="small">{{ plaintiff.cellphone }}</a>
                </div>
            </div>
            <div class="block" v-if="plaintiff.email">
                <div class="information">
                    <p class="small">Adresse courriel</p>
                    <a :href="'mailto:' + plaintiff.email" class="small">{{ plaintiff.email }}</a>
                </div>
            </div>
            <div class="block">
                <div class="information">
                    <p class="small">Suivi automatisé par courriel</p>
                    <p class="small">{{ plaintiff.emailNotification ? 'Oui' : 'Non' }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopInformations',
    props: {
        plaintiff: Object,
        reportAt: String,
        inspectorName: String,
        administrator: Object,
        source: String,
        plannedFor: String,
        apartment: String
    },
    computed: {
        status() {
            return this.$store.getters.requestStatus(this.$route.params.requestID)
        }
    },
    methods: {
        getReportDate(date) {
            var day = new Date(date).getDate()
            var month = this.$t('request.details.informations.months[' + new Date(date).getMonth() + ']')
            var year = new Date(date).getFullYear()

            return day + ' ' + month + ' ' + year
        }
    }
}
</script>
