class Collect {

    constructor() {
        this._filterValues = null // on pourrait appeler reset() direct mais j’aime bien avoir mes valeurs dans le constructeur
        this.reset()
    }

    setFilter(filters) {
        this._filterValues = filters.slice() // faire un clone du tableau pour virer les observers de Vue
    }

    // reset des valeurs
    reset() {
        this._filterValues = [];
    }

    // action du filter, list = liste à filtrer
    filter(list) {
        // si pas de filtre, on ne touche à rien
        if ( !this.isActiveFilter() ) return list

        list.forEach((item) => {
            if (item.flag > 0) { // uniquement pour les elements à 1
                for (let index = 0; index < item.collectTypesID.length; index++) {
                    item.flag = (this._filterValues.indexOf(item.collectTypesID[index]) > -1) ? 1 : 0
                    if (item.flag == 1) {
                        return
                    }
                }
            }
        })
        return list
    }

    // indique si il existe un filtre actiellement chargé ?
    isActiveFilter() {
        return ( this._filterValues.length > 0 ) ? true : false
    }

}

export default new Collect()
