<template>
    <div class="top">
        <div class="status">
            <p class="small" :class="status">&#9679; {{ $t('request.status.' + status) }}</p>
        </div>
        <div class="type">
            <svg v-if="intervention && intervention.binQty > 1" role="img" class="icon">
                <use xlink:href="/images/symbols.svg#ico-more"></use>
            </svg>
            <svg v-if="intervention" role="img" class="icon">
                <use v-if="intervention.collectTypeID <= 4" :xlink:href="'/images/symbols.svg#ico-collectType' + intervention.collectTypeID"></use>
                <use v-else xlink:href="/images/symbols.svg#ico-collectType5"></use>
            </svg>
            <svg v-else :key="type" v-for="type in collectTypes" role="img" class="icon">
                <use v-if="type == 1 || type == 2 || type == 3 || type == 4" :xlink:href="'/images/symbols.svg#ico-collectType' + type"></use>
                <use v-else xlink:href="/images/symbols.svg#ico-collectType5"></use>
            </svg>
        </div>
        <div class="id">
            <h5 class="small">{{ reportID }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusBlock',
    props: {
        collectTypes: Array,
        reportID: String,
        intervention: Object
    },
    computed: {
        status() {
            return this.$store.getters.requestStatus(this.reportID)
        }
    }
}
</script>