<template>
    <div class="login">
        <div class="background greyGrey"></div>
        <img id="logo" src="/images/logo.svg" alt="Logo MRC de Roussillon">
        <div class="loginTitle">
            <h1>{{ $t('login.title1') }}</h1>
            <h1 class="bottomText">{{ $t('login.title2') }}</h1>
        </div>
        <form>
            <input type="email" name="username" id="username" :placeholder="$t('login.username')" v-model="username">
            <input type="password" name="password" id="password" :placeholder="$t('login.password')" v-model="password" v-on:keyup.enter="login($event)">
        </form>
        <a id="loginBtn" class="btn" @click="login($event)">{{ $t('login.login') }}</a>
        <p class="copyright small" v-html="$t('login.copyright')"></p>
        <Popup />
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'
import Popup from '@/components/popup.vue'

export default {
    name: 'Login',
    components: {
        Popup
    },
    data() {
        return {
            username: '',
            password: ''
        }
    },
    mounted() {
        EventBus.$on('authStatus', result => {
            if (result){
                if (localStorage.token) {
                    if (this.$store.getters.isResults == false) {
                        this.$store.dispatch('getRequests')
                    }
                }
                this.$router.push({ name: 'home' })
            } else {
                document.getElementById('loginBtn').classList.remove('disabled')
            }
        })
    },
    beforeDestroy() {
        EventBus.$off('authStatus')
    },
    methods: {
        login(e) {
            e.target.classList.add('disabled')
            if (this.username == '' && this.password == '') {
                EventBus.$emit('openPopup', {'type': 'alert', 'title': this.$t('login.popup.allIncomplete.title'), 'description': this.$t('login.popup.allIncomplete.description'), 'actions': this.$t('login.popup.allIncomplete.actions') })
                e.target.classList.remove('disabled')
            } else if (this.username == '') {
                EventBus.$emit('openPopup', {'type': 'alert', 'title': this.$t('login.popup.usernameIncomplete.title'), 'description': this.$t('login.popup.usernameIncomplete.description'), 'actions': this.$t('login.popup.usernameIncomplete.actions') })
                e.target.classList.remove('disabled')
            } else if (this.password == '') {
                EventBus.$emit('openPopup', {'type': 'alert', 'title': this.$t('login.popup.passwordIncomplete.title'), 'description': this.$t('login.popup.passwordIncomplete.description'), 'actions': this.$t('login.popup.passwordIncomplete.actions') })
                e.target.classList.remove('disabled')
            } else {
                this.$store.dispatch('auth', { username: this.username, password: this.password })
            }
        }
    }
}
</script>
