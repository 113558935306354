<template>
    <div class="home">
        <div class="background" :class="[viewShowFilters ? 'blueBlue' : 'blueGrey']"></div>
        <NavigationBar color="blue" :title="viewShowFilters ? $t('home.filters.title') : $t('home.title')" :enableBack="viewShowFilters" :refresh="!viewShowFilters" :origin="viewShowFilters ? 'filters' : 'home'" />
        <div class="controls" v-if="!viewShowFilters">
            <div class="topControls">
                <div class="segmentedControl">
                    <input name="segmentedButton" type="radio" id="list" @click="toggleView('list'); updateMapCenter()" :checked="viewShowList">
                    <label class="list" for="list">{{ $t('home.controls.segmented[0]') }}</label>
                    <input name="segmentedButton" type="radio" id="map" @click="toggleView('map')" :checked="viewShowMap">
                    <label class="map" for="map">{{ $t('home.controls.segmented[1]') }}</label>
                    <div id="background"></div>
                </div>
                <div class="selectControl">
                    <select id="selectSort" class="select" name="sort" @change="getSelectedSort">
                        <option :selected="selectedSort == 'id'" value="id" style="display:none;">{{ $t('home.controls.sort[0]') }}</option>
                        <option :selected="selectedSort == 'newest'" value="newest">{{ $t('home.controls.sort[1]') }}</option>
                        <option :selected="selectedSort == 'oldest'" value="oldest">{{ $t('home.controls.sort[2]') }}</option>
                        <option :selected="selectedSort == 'closest'" value="closest">{{ $t('home.controls.sort[3]') }}</option>
                        <option :selected="selectedSort == 'statut'" value="statut">{{ $t('home.controls.sort[4]') }}</option>
                    </select>
                    <div class="arrow"><img src="/images/arrow.svg" alt="Arrow"></div>
                </div>
            </div>
            <a class="btn filterControl" @click="toggleView('filters'); updateMapCenter()"><img src="/images/filter.svg" alt="Filter">{{ $tc('home.controls.filter', this.selectedFiltersLength, {count: this.selectedFiltersLength}) }}</a>
        </div>
        <div id="viewMap" v-if="viewShowMap">
            <div v-if="userPositionExist" id="recenter" @click="mapUserPositionCenter()"><svg role="img" class="icon" :class="[mapUserPositionCentered ? 'active' : '']"><use xlink:href="/images/symbols.svg#ico-location"></use></svg></div>
            <GmapMap ref="mapRef" map-type-id="roadmap" @dragstart="mapCenterChanged()" @zoom_changed="mapZoomChanged()" :center="mapUserPosition" :zoom="14" :options="mapOptions">
                <gmap-custom-marker :key="index" v-for="(request, index) in requestsInMap" :marker="{lat: request.latitude, lng: request.longitude}" :clickable="true" :draggable="false" @click.native="mapShowRequest(request)" class="marker" :class="[request.priority == 50 ? 'priority' : request.priority == 25 ? 'priorityMed' : '', request == mapSelectedRequest[0] ? 'clicked' : '']">
                    <svg v-if="request.interventions.length == 1" role="img" class="icon"><use v-if="request.collectTypesID[0] <= 5" :xlink:href="'/images/symbols.svg#ico-collectType' + request.collectTypesID[0]"></use><use v-else xlink:href="/images/symbols.svg#ico-collectType5"></use></svg>
                    <svg v-else role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-more"></use></svg>
                </gmap-custom-marker>
                <gmap-custom-marker v-if="userPositionExist" id="mapUserPosition" :class="'mapZoomLevel' + mapZoomLevel" :marker="mapUserPosition" :clickable="true" @click.native="mapUserPositionCenter()" />
            </GmapMap>
            <div v-if="mapSelectedRequest.length > 0" class="mapOverlay" @click="closeSelectedRequest()"></div>
            <div v-if="mapSelectedRequest.length > 0" class="mapRequestContainer">
                <Request :request="mapSelectedRequest[0]" origin="map" />
            </div>
        </div>
        <div id="viewList" v-if="viewShowList && !viewShowFilters">
            <Request :key="request.id" v-for="request in requests" :request="request" origin="list" />
        </div>
        <div id="filterMenu" v-if="viewShowFilters">
            <div class="section">
                <h2>{{ $t('home.filters.section.city') }} ({{ cities.length }})</h2>
                <a class="btn sort small" :class="[selectedFilters.includes(city.city) ? 'light' : '']" :key="index" v-for="(city, index) in cities" @click="toggleSelected(city.city)">{{ city.city }}</a>
            </div>
            <div class="section">
                <h2>{{ $t('home.filters.section.collect') }}</h2>
                <a class="btn sort small" :class="[selectedFiltersC.includes(collectType.id) ? 'light' : '']" :key="collectType.id" v-for="collectType in collectTypes" @click="toggleSelectedC(collectType.id)">{{ collectType.name }}</a>
            </div>
            <div class="section last">
                <h2>{{ $t('home.filters.section.breakage') }}</h2>
                <a class="btn sort small" :class="[selectedFiltersB.includes(breakType.id) ? 'light' : '']" :key="breakType.id" v-for="breakType in breakageTypes" @click="toggleSelectedB(breakType.id)">{{ breakType.name }}</a>
            </div>
            <div v-observe-visibility="visibilityChanged"></div>
            <div class="buttonsB" :class="[isVisible ? 'removeGradient' : '']">
                <div class="btn white" @click="resetFilters()">{{ $t('home.filters.actions.reset') }}</div>
                <div class="btn light" @click="applyFilters(true)">{{ $t('home.filters.actions.apply') }}</div>
            </div>
        </div>
        <div v-if="!viewShowFilters" id="refreshContainer" :class="[refreshInProgress ? 'brighten' : '']"></div>
        <router-link :to="{ path: 'create' }" id="addBtn" v-if="mapSelectedRequest.length == 0 && !viewShowFilters">
            <svg role="img" class="icon"><use xlink:href="/images/symbols.svg#ico-add"></use></svg>
        </router-link>
        <div id="tabbarContainer" v-if="!viewShowFilters">
            <div class="tabbar">
                <div @click="tab(0)" id="tabItem0" :class="[selectedTabItem == 0 ? 'tabItem selected' : 'tabItem']">Tout voir<sup>{{ requestsLength[0] }}</sup></div>
                <div @click="tab(1)" id="tabItem1" :class="[selectedTabItem == 1 ? 'tabItem selected' : 'tabItem']">Mes requêtes<sup>{{ requestsLength[1] }}</sup></div>
                <div @click="tab(2)" id="tabItem2" :class="[selectedTabItem == 2 ? 'tabItem selected' : 'tabItem']">Non attribuées<sup>{{ requestsLength[2] }}</sup></div>
                <div @click="tab(3)" id="tabItem3" :class="[selectedTabItem == 3 ? 'tabItem selected' : 'tabItem']">Complétées<sup>{{ requestsLength[3] }}</sup></div>
                <div @click="tab(4)" id="tabItem4" :class="[selectedTabItem == 4 ? 'tabItem selected' : 'tabItem']">Annulées<sup>{{ requestsLength[4] }}</sup></div>
                <div @click="tab(5)" id="tabItem5" :class="[selectedTabItem == 5 ? 'tabItem selected' : 'tabItem']">Archives<sup>{{ requestsLength[5] }}</sup></div>
            </div>
        </div>
        <Popup />
    </div>
</template>

<script>
import { EventBus } from '@/classes/eventBus.js'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import NavigationBar from '@/components/navigationBar.vue'
import Popup from '@/components/popup.vue'
import Request from '@/components/request.vue'

export default {
    name: 'Home',
    components: {
        NavigationBar,
        'gmap-custom-marker': GmapCustomMarker,
        Request,
        Popup
    },
    data() {
        return {
            pageView: this.$route.params.pageView ? this.$route.params.pageView : 'list',
            viewShowList: false,
            viewShowMap: false,
            viewShowFilters: false,
            selectedFiltersLength: 0,
            selectedFiltersToSend: [{city: null, collect: null, breakage: null}],
            selectedFilters: [],
            selectedFiltersC: [],
            selectedFiltersB: [],
            sortingSelected: [],
            userPositionExist: false,
            mapSelectedRequest: [],
            mapUserPosition: {lat: 45.33212780000000208247001864947378635406494140625, lng: -73.68582490000000007057678885757923126220703125},
            mapUserPositionCentered: false,
            mapZoomLevel: 0,
            mapOptions: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                gestureHandling: 'greedy',
                backgroundColor: '#f4f4f4',
                clickableIcons: false,
                styles: [
                {
                    'featureType': 'poi',
                    'elementType': 'labels',
                    'stylers': [
                    {
                        'visibility': 'off'
                    }
                    ]
                },
                {
                    'featureType': 'transit',
                    'elementType': 'labels',
                    'stylers': [
                    {
                        'visibility': 'off'
                    }
                    ]
                }
                ]
            },
            selectedTabItem: 0,
            selectedSort: 'id',
            asLoaded: false,
            refreshInProgress: false,
            isVisible: false
        }
    },
    computed: {
        collectTypes() {
            return this.$store.getters.allCollectTypes
        },
        breakageTypes() {
            return this.$store.getters.allBreakageTypes
        },
        cities() {
            return this.$store.getters.allCities
        },
        requests() {
            var self = this
            return this.$store.getters.allRequests.filter(function (request) {
                if(request.flag == 1) {
                    if (self.selectedTabItem == 0) {
                        if (request.status.indexOf('COMPLETED') === -1 && request.status.indexOf('CANCELED') === -1) {
                            return request
                        }
                    }
                    if (self.selectedTabItem == 1) {
                        if (request.inspectorName != null && request.status.indexOf('COMPLETED') === -1 && request.status.indexOf('CANCELED') === -1) {
                            return request.inspectorName.indexOf(localStorage.inspectorName) !== -1
                        } else {
                            return false
                        }
                    }
                    if (self.selectedTabItem == 2) {
                        return request.status.indexOf('WAITING') !== -1
                    }
                    if (self.selectedTabItem == 3) {
                        return request.status.indexOf('COMPLETED') !== -1
                    }
                    if (self.selectedTabItem == 4) {
                        return request.status.indexOf('CANCELED') !== -1
                    }
                    if (self.selectedTabItem == 5) {
                        if (request.status.indexOf('COMPLETED') !== -1 || request.status.indexOf('CANCELED') !== -1) {
                            return request
                        }
                    }
                }
            }).sort((a, b) => {
                switch(self.selectedSort) {
                    case 'id':
                        return a.reportAt > b.reportAt ? -1 : a.reportAt < b.reportAt ? 1 : 0 //Order by most recent
                    case 'newest':
                        return a.reportAt > b.reportAt ? -1 : a.reportAt < b.reportAt ? 1 : 0 //Order by most recent
                    case 'oldest':
                        return a.reportAt > b.reportAt ? 1 : a.reportAt < b.reportAt ? -1 : 0 //Order by less recent
                    case 'closest':
                        return a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0 //Order by distance
                    case 'statut':
                        return a.status > b.status ? -1 : a.status < b.status ? 1 : 0 //Order by Statut - ID
                }
            })
        },
        requestsInMap() {
            var self = this
            return this.$store.getters.allRequests.filter(function (request) {
                if(request.flag == 1 && (request.latitude != null && request.longitude != null)) {
                    if (self.selectedTabItem == 0) {
                        if (request.status.indexOf('COMPLETED') === -1 && request.status.indexOf('CANCELED') === -1) {
                            return request
                        }
                    }
                    if (self.selectedTabItem == 1) {
                        if (request.inspectorName != null && request.status.indexOf('COMPLETED') === -1 && request.status.indexOf('CANCELED') === -1) {
                            return request.inspectorName.indexOf(localStorage.inspectorName) !== -1
                        } else {
                            return false
                        }
                    }
                    if (self.selectedTabItem == 2) {
                        return request.status.indexOf('WAITING') !== -1
                    }
                    if (self.selectedTabItem == 3) {
                        return request.status.indexOf('COMPLETED') !== -1
                    }
                    if (self.selectedTabItem == 4) {
                        return request.status.indexOf('CANCELED') !== -1
                    }
                    if (self.selectedTabItem == 5) {
                        if (request.status.indexOf('COMPLETED') !== -1 || request.status.indexOf('CANCELED') !== -1) {
                            return request
                        }
                    }
                }
            }).sort((a, b) => {
                switch(self.selectedSort) {
                    case 'id':
                        return a.reportAt > b.reportAt ? -1 : a.reportAt < b.reportAt ? 1 : 0 //Order by most recent
                    case 'newest':
                        return a.reportAt > b.reportAt ? -1 : a.reportAt < b.reportAt ? 1 : 0 //Order by most recent
                    case 'oldest':
                        return a.reportAt > b.reportAt ? 1 : a.reportAt < b.reportAt ? -1 : 0 //Order by less recent
                    case 'closest':
                        return a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0 //Order by distance
                    case 'statut':
                        return a.status > b.status ? -1 : a.status < b.status ? 1 : 0 //Order by Statut - ID
                }
            })
        },
        requestsLength() {
            return this.$store.getters.allRequestsLength
        }
    },
    watch: {
        userPositionExist() {
            if (this.userPositionExist) {
                this.mapGetLocation(false)

                for(var i = 0; i < this.requests.length; i++) {
                    this.requests[i].distance = this.distance(this.requests[i].latitude, this.requests[i].longitude, this.mapUserPosition.lat, this.mapUserPosition.lng, 'K')
                }
            }
        }
    },
    beforeDestroy() {
        EventBus.$off('refresh')
        EventBus.$off('filtersBack')
        this.updateMapCenter()
    },
    mounted() {
        this.toggleView(this.pageView)
        if (localStorage.token) {
            this.mapGetLocation(false)
        } else {
            this.$router.push({ name: 'login' })
        }

        EventBus.$on('refresh', result => {
            if (result) {
                this.refreshApp()
            }
        })

        EventBus.$on('filtersBack', result => {
            if (result) {
                this.isVisible = false
                this.toggleView(this.pageView)
            }
        })

        if (JSON.parse(localStorage.getItem('selectedFiltersLength')) != null) {
            this.selectedFiltersLength = localStorage.getItem('selectedFiltersLength')
        } else {
            this.selectedFiltersLength = 0
        }

        if (JSON.parse(localStorage.getItem('selectedFilters')) != null) {
            this.selectedFilters = JSON.parse(localStorage.getItem('selectedFilters'))
        } else {
            this.selectedFilters = []
        }

        if (JSON.parse(localStorage.getItem('selectedFiltersC')) != null) {
            this.selectedFiltersC = JSON.parse(localStorage.getItem('selectedFiltersC'))
        } else {
            this.selectedFiltersC = []
        }

        if (JSON.parse(localStorage.getItem('selectedFiltersB')) != null) {
            this.selectedFiltersB = JSON.parse(localStorage.getItem('selectedFiltersB'))
        } else {
            this.selectedFiltersB = []
        }

        if (JSON.parse(localStorage.getItem('selectedTabItem')) != null) {
            this.selectedTabItem = localStorage.getItem('selectedTabItem')
            this.tab(this.selectedTabItem)
        } else {
            this.selectedTabItem = 0
            this.tab(this.selectedTabItem)
        }


        this.$nextTick(() => {
        this.applyFilters(false)
            if (localStorage.getItem('selectedSort') != null) {
                this.selectedSort = localStorage.getItem('selectedSort')
                document.getElementById('selectSort').options.selectedIndex = this.selectedSort
            }
        })
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible
        },
        refreshApp() {
            this.refreshInProgress = true
            this.$store.dispatch('getRequests')

            setTimeout(() => {
                this.selectedFiltersLength = 0
                this.selectedTabItem = 0
                this.selectedSort = 'id'
                this.selectedFilters = []
                this.selectedFiltersC = []
                this.selectedFiltersB = []

                localStorage.setItem('selectedFiltersLength', this.selectedFiltersLength)
                localStorage.setItem('selectedTabItem', this.selectedTabItem)
                localStorage.setItem('selectedSort', this.selectedSort)
                localStorage.setItem('selectedFilters', JSON.stringify(this.selectedFilters))
                localStorage.setItem('selectedFiltersC', JSON.stringify(this.selectedFiltersC))
                localStorage.setItem('selectedFiltersB', JSON.stringify(this.selectedFiltersB))

                document.getElementById('selectSort').options.selectedIndex = 0
                this.refreshInProgress = false
            }, 1750);
        },
        tab(selected) {
            this.selectedTabItem = selected
            localStorage.setItem('selectedTabItem', this.selectedTabItem)
        },
        getSelectedSort() {
            if(document.getElementById('selectSort').options.selectedIndex > -1) {
                this.selectedSort = document.getElementById('selectSort').options[document.getElementById('selectSort').options.selectedIndex].value
                localStorage.setItem('selectedSort', this.selectedSort)
            }
        },
        distance(lat1, lon1, lat2, lon2, unit) {
            if ((lat1 == lat2) && (lon1 == lon2)) {
                return 0
            }
            else {
                var radlat1 = Math.PI * lat1/180
                var radlat2 = Math.PI * lat2/180
                var theta = lon1-lon2
                var radtheta = Math.PI * theta/180
                var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
                if (dist > 1) {
                    dist = 1
                }
                dist = Math.acos(dist)
                dist = dist * 180/Math.PI
                dist = dist * 60 * 1.1515
                if (unit=="K") { dist = dist * 1.609344 }
                if (unit=="N") { dist = dist * 0.8684 }
                return dist
            }
        },
        applyFilters(shouldToggleView) {
            this.selectedFiltersToSend = {city: this.selectedFilters, collect: this.selectedFiltersC, breakage: this.selectedFiltersB}

            this.$store.dispatch('filterRequests', this.selectedFiltersToSend)
            this.isVisible = false
            if (shouldToggleView) {
                this.toggleView(this.pageView)
            }
        },
        resetFilters() {
            this.selectedFiltersLength = 0
            this.selectedFilters = []
            this.selectedFiltersC = []
            this.selectedFiltersB = []

            localStorage.setItem('selectedFiltersLength', this.selectedFiltersLength)
            localStorage.setItem('selectedFilters', JSON.stringify(this.selectedFilters))
            localStorage.setItem('selectedFiltersC', JSON.stringify(this.selectedFiltersC))
            localStorage.setItem('selectedFiltersB', JSON.stringify(this.selectedFiltersB))
            this.selectedFiltersToSend = {city: this.selectedFilters, collect: this.selectedFiltersC, breakage: this.selectedFiltersB}

            this.$store.dispatch('filterRequests', this.selectedFiltersToSend)
            this.isVisible = false
        },
        toggleSelected(index) {
            if(this.selectedFilters.includes(index)) {
                this.selectedFilters.splice(this.selectedFilters.indexOf(index), 1)
                this.selectedFiltersLength--
            } else {
                this.selectedFilters.push(index)
                this.selectedFiltersLength++
            }

            localStorage.setItem('selectedFiltersLength', this.selectedFiltersLength)
            localStorage.setItem('selectedFilters', JSON.stringify(this.selectedFilters))
        },
        toggleSelectedC(index) {
            if(this.selectedFiltersC.includes(index)) {
                this.selectedFiltersC.splice(this.selectedFiltersC.indexOf(index), 1)
                this.selectedFiltersLength--
            } else {
                this.selectedFiltersC.push(index)
                this.selectedFiltersLength++
            }

            localStorage.setItem('selectedFiltersLength', this.selectedFiltersLength)
            localStorage.setItem('selectedFiltersC', JSON.stringify(this.selectedFiltersC))
        },
        toggleSelectedB(index) {
            if(this.selectedFiltersB.includes(index)) {
                this.selectedFiltersB.splice(this.selectedFiltersB.indexOf(index), 1)
                this.selectedFiltersLength--
            } else {
                this.selectedFiltersB.push(index)
                this.selectedFiltersLength++
            }

            localStorage.setItem('selectedFiltersLength', this.selectedFiltersLength)
            localStorage.setItem('selectedFiltersB', JSON.stringify(this.selectedFiltersB))
        },
        btnClick(el) {
            if (el.target.classList.contains('sort')) {
                el.target.classList.replace('sort', 'light')
                this.selectedFilters.push(el.target.innerHTML)
            } else {
                el.target.classList.replace('light', 'sort')
                this.selectedFilters.slice(el.target.innerHTML)
            }
        },
        toggleView(view) {
            switch (view) {
                case 'list':
                    this.viewShowList = true
                    this.viewShowMap = false
                    this.viewShowFilters = false
                    break
                case 'map':
                    this.mapSelectedRequest = []
                    this.viewShowList = false
                    this.viewShowMap = true
                    this.viewShowFilters = false
                    this.mapGetLocation(true)
                    break
                case 'filters':
                    this.viewShowList = false
                    this.viewShowMap = false
                    this.viewShowFilters = true
                    break
            }
        },
        closeSelectedRequest() {
            if (this.mapSelectedRequest.length > 0) {
                this.mapActions(this.mapSelectedRequest[0].latitude, this.mapSelectedRequest[0].longitude, 14)
                this.mapSelectedRequest = []
            }
        },
        mapShowRequest(item) {
            if (!this.mapSelectedRequest.includes(item)) {
                this.mapSelectedRequest.push(item)
                this.mapActions(item.latitude, item.longitude, 16)
            }
        },
        mapActions(latitude, longitude, zoom) {
            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo({lat: latitude, lng: longitude})
                map.setZoom(zoom)
            })
        },
        mapGetLocation(isMapView) {
            if (navigator.geolocation) {
                if(isMapView) {
                    navigator.geolocation.getCurrentPosition(
                        this.showUserPosition
                    , (error) => {
                        this.userPositionExist = false
                        if (this.$refs.mapRef) {
                            this.showUserPosition()
                        }
                        if(error.code == 1) {
                            console.log('User denied Geolocation') // eslint-disable-line no-console
                        }
                    }, { enableHighAccuracy: true, timeout: 5000 })
                } else {
                    navigator.geolocation.getCurrentPosition(
                        this.setUserPosition
                    , (error) => {
                        this.userPositionExist = false
                        if(error.code == 1) {
                            console.log('User denied Geolocation') // eslint-disable-line no-console
                        }
                    }, { enableHighAccuracy: true, timeout: 5000 })
                }
            } else {
                alert('Geolocation is not supported by this browser.')
            }
        },
        setUserPosition(position) {
            if (!this.userPositionExist) {
                if (localStorage.getItem('mapLat') && localStorage.getItem('mapLng')) {
                    this.mapUserPosition = {lat: parseFloat(localStorage.getItem('mapLat')), lng: parseFloat(localStorage.getItem('mapLng'))}
                } else {
                    this.mapUserPosition = {lat: position.coords.latitude, lng: position.coords.longitude}
                }
                this.userPositionExist = true
            }   
        },
        showUserPosition(position) {
            this.setUserPosition(position)

            this.$refs.mapRef.$mapPromise.then((map) => {
                if (localStorage.getItem('mapLat') && localStorage.getItem('mapLng')) {
                    map.panTo({lat: parseFloat(localStorage.getItem('mapLat')), lng: parseFloat(localStorage.getItem('mapLng'))})
                } else {
                    map.panTo({lat: position.coords.latitude, lng: position.coords.longitude})
                }  
                map.setZoom(16)
            })

            this.mapUserPositionCentered = true
        },
        mapUserPositionCenter() {
            this.mapUserPositionCentered = true

            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo({lat: this.mapUserPosition.lat, lng: this.mapUserPosition.lng})
                map.setZoom(16)
            })
        },
        mapCenterChanged() {
            if(this.mapUserPositionCentered == true) {
                this.mapUserPositionCentered = false
            }
        },
        mapZoomChanged() {
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.mapZoomLevel = map.getZoom()

                if (map.getZoom() != 16 && this.mapUserPositionCentered == true) { 
                    this.mapUserPositionCentered = false
                }
            })
        },
        updateMapCenter() {
            if (this.$refs.mapRef) {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    localStorage.setItem('mapLat', map.center.lat())
                    localStorage.setItem('mapLng', map.center.lng())
                })
            } 
        }
    }
}
</script>
