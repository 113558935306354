import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuex from 'vuex'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyArG4dg2_d8D8uONOezfKkze-G__mAuaMM',
        region: 'CA',
        language: 'fr'
    },
    installComponents: true
})

Vue.use(Vuex)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

